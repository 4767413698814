import { css } from '@emotion/react'
import colors from 'styles/colors'
import { transition, SIDEBAR_WIDTH, HEADER_HEIGHT, zIndexes } from 'styles/constants'
import { mqMax, mqMin } from 'styles/mixins/mediaQueries'

const SIDEBAR_WIDTH_MINI = '4rem'
const VERTICAL_PADDING = '1rem'

export const sidebar = ({ isExpanded } = {}) => css`
  position: fixed;
  height: calc(100% - ${HEADER_HEIGHT} - ${VERTICAL_PADDING} * 2);
  width: 100%;
  max-width: ${SIDEBAR_WIDTH};
  background-color: ${colors.sidebarLight};
  padding: ${VERTICAL_PADDING} 0;
  z-index: ${zIndexes.top};
  ${transition};

  ${!isExpanded
  && `
    ${mqMax.md} {
      max-width: ${SIDEBAR_WIDTH_MINI};
    }
  `}
`

export const sidebarSpacer = css`
  ${sidebar()};
  position: unset;
  background-color: ${colors.transparent};

  ${mqMax.md} {
    max-width: ${SIDEBAR_WIDTH_MINI};
  }
`

export const routesContainer = css`
  margin: 0;
  padding: 0;

  li {
    display: contents;
  }
`

export const routeContainer = css`
  text-decoration: none;
`

export const route = ({ isChild, isActive }) => css`
  display: flex;
  align-items: center;
  padding: 1rem 1.25rem;
  cursor: pointer;
  color: ${colors.gray};
  ${transition};

  ${isActive
	&& `
    border-left: 0.15rem solid;
		border-image: linear-gradient( ${colors.pink}, ${colors.orange}) 0 100%;
    background: ${colors.sidebarDark};
    color: ${colors.white};
  `};

  ${isChild
  && `
		padding-left: 2.5rem;
	`}

  &:hover {
    background: ${colors.sidebarDark};
    color: ${colors.white};
  }
`

export const routeLabel = ({ isExpanded }) => css`
  font-size: small;
  margin-left: 0.75rem;
  ${transition};

  ${!isExpanded
  && `
    ${mqMax.md} {
      font-size: 0;
      margin: 0;
    }
  `};
`

export const routeIcon = css`
  font-size: medium;
  width: 1rem;
  height: 1rem;
`

export const rightIconDiv = css`
  display: flex;
  justify-content: center;
  align-items: center;
  right: 1rem;
  position: absolute;
`

export const rightIcon = css`
  font-size: small;
`

export const buttonExpand = css`
  position: absolute;
  bottom: 1rem;
  right: 1rem;

  ${mqMin.md} {
    display: none;
  }
`

export const versionRow = css`
  text-align: center;
`

export const versionText = css`
  font-size: 0.8rem;
  color: white;
`
