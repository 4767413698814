import { css } from '@emotion/react'
import { transparentize } from 'polished'
import colors from 'styles/colors'
import { borderRadius } from 'styles/constants'

export const card = css`
  padding: 0.5rem;
  box-sizing: border-box;
  background: ${colors.white};
  box-shadow: 0rem 0rem 1rem 0rem ${transparentize(0.75, colors.gray)};
  border-radius: ${borderRadius};
`
