import React from 'react'

import * as style from './styles'

const ProgressBar = (props) => {
  const { user } = props
  const userData = {
    bio: user?.bio,
    name: user?.name,
    subtitle: user?.subtitle,
    email: user?.email,
    username: user?.username,
    image: user?.image,
    coverImage: user?.coverImage,
    interests: user?.interests,
  }

  const calculatePercentage = (obj) => {
    const total = Object.keys(obj).length
    let filled = 0

    for (const key in obj) {
      if (
        ['bio', 'name', 'subtitle', 'email', 'username', 'coverImage'].includes(
          key
        )
      ) {
        filled = filled + (obj[key] && obj[key].length ? 1 : 0)
      } else if (
        ['image'].includes(key) &&
        obj[key] !== 'https://api.crowdmouth.com/avatar-icon.png'
      ) {
        filled = filled + (obj[key] && obj[key].length ? 1 : 0)
      } else {
        filled = filled + (Array.isArray(obj[key]) && obj[key].length ? 1 : 0)
      }
    }

    return Math.floor(filled / total * 100)
  }

  return (
    <div css={style.container}>
      <div
        css={style.filler}
        style={{ width: `${calculatePercentage(userData)}%` }}></div>
      <p css={style.completion}>
        Profile Completion {calculatePercentage(userData)}%
      </p>
    </div>
  )
}

export default ProgressBar
