import React, { Fragment, useState } from 'react'
import PropTypes from 'prop-types'

import Icon from 'components/Icon'
import ImageAvatar from 'components/ImageAvatar'
import BadgeWrapper from 'components/BadgeWrapper'

import { router, Link } from '~/lib/router'

import * as style from './styles'
import { isNil } from 'lodash'
import SettingsModal from '../SettingsModal'

const LogoAndSearch = () => (
  <div css={style.logoAndSearchContainer}>
    <div
      css={style.logo}
      onClick={() => window.location.replace('https://www.crowdmouth.com')}>
      <Icon icon='crowdmouth' />
    </div>
    {/* <Input
      icon='search'
      theme='light'
      css={style.inputSearch}
      placeholder='Search for anything'
    /> */}
  </div>
)

// todo: handle notifications and remove constant for badge color
const Notifications = () => {
  const BADGE_COLOR = 'blue'
  return (
    <Fragment>
      <BadgeWrapper icon='chatBubble' isActive color={BADGE_COLOR} />
      <BadgeWrapper icon='bell' isActive color={BADGE_COLOR} />
    </Fragment>
  )
}

const User = ({ user }) => {
  if (isNil(user)) {
    return null
  }

  const { name, image } = user

  return (
    <Link href='/update-info'>
      <div id='header_userInfo' css={style.userInfo}>
        <span css={style.username}>{name}</span>
        <ImageAvatar
          isRounded
          src={image}
          css={style.avatar}
          onClick={() => router.push(router.routes.updateInfo)}
        />
      </div>
    </Link>
  )
}

/**
 * Header / Navbar
 *
 * @component
 * @usage `import Header from 'components/Header'`
 */
const Header = ({ user, isPremium, authStore, rootStore }) => {
  const [open, setOpen] = useState(false)
  return (
    <header css={style.header}>
      <LogoAndSearch />
      <div id='header_userInfo' css={style.actionsContainer}>
        <div css={style.settingsContainer}>
          <Icon
            icon='settings'
            role='presentation'
            css={style.settings}
            onClick={() => setOpen(!open)}
          />
          {open && (
            <SettingsModal
              authStore={authStore}
              setOpen={setOpen}
              rootStore={rootStore}
            />
          )}
        </div>
        {!isPremium && (
          <Link href={router.routes.userMassMessages}>
            <span css={style.premium}>Become a PRO Member</span>
          </Link>
        )}
        {/* <Notifications /> */}
        <User user={user} />
      </div>
    </header>
  )
}

Header.propTypes = {
  user: PropTypes.object,
  isPremium: PropTypes.bool,
  authStore: PropTypes.object,
}

Header.defaultProps = {
  user: {},
  authStore: {},
  isPremium: false,
}

export default Header
