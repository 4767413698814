import Service from './Service'
import presentApiPagination from 'presenters/apiPaginationPresenter'
import presentInterest from 'presenters/interestPresenter'

export const SERVICES = {
  getAllInterests: {
    type: 'get',
    present: resp => {
      return {
        success: resp?.success,
        data: (resp?.data?.items || []).map(presentInterest),
        ...presentApiPagination(resp),
      }
    },
  },
}

/**
 * Instance of Interests Service.
 *
 * @class
 * @usage `import InterestsService from 'network/InterestsService'`
 */
export default class InterestsService extends Service {
  constructor(props) {
    super(props)
    this.services = SERVICES
  }

  serviceBase() {
    return 'interests'
  }
}
