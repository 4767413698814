import Store, { setObservables } from './Store'
import UserCampaignsService from 'network/UserCampaignsService.js'
import { observable, action, computed, toJS } from 'mobx'

const OBSERVABLES = {
  _observableOne: { type: observable, defaultValue: '' },
}

class UserCampaignsStore extends Store {
  constructor(initialData) {
    super(initialData)
    setObservables(this, initialData, OBSERVABLES)
  }

  service() {
    return new UserCampaignsService(this.rootStore)
  }

  setObservableOne(value) {
    this._observableOne = value
  }

  get observableOne() {
    return toJS(this._observableOne)
  }

  returnSomething() {
    return 'Something from UserCampaignsStore'
  }
}

export default UserCampaignsStore
