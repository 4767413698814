import presentUser from 'presenters/userPresenter'
import { isEmpty } from 'lodash'

const present = (fan = {}) => {
  if (isEmpty(fan)) { return {} }

  return {
    id: fan?.id,
    balance: fan?.balance,
    clicksCount: fan?.clicks_count,
    sharesCount: fan?.shares_count,
    user: presentUser(fan?.user),
  }
}

export default present
