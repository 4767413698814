export const CAMPAIGN_STATUSES = [
  { key: 'active', label: 'Active' },
  { key: 'inactive', label: 'Inactive' },
]

export const REWARD_STATUSES = [
  { key: 'pending', label: 'Pending', icon: 'pending' },
  { key: 'shipped', label: 'Fulfilled', icon: 'shipped' },
  { key: 'completed', label: 'Completed', icon: 'distributed' },
]

export const REWARD_TYPES = {
  video: {
    label: 'Video Shoutout', icon: 'video',
  },
  photo: {
    label: 'Social Media Tagging', icon: 'socials',
  },
  text: {
    label: 'Discount Coupon', icon: 'coupon',
  },
  physical: {
    label: 'Physical Item', icon: 'giftFilled',
  },
  outside_app: {
    label: 'Custom', icon: 'customize',
  },
  custom: {
    label: 'Custom', icon: 'customize',
  },
}

export const HEADER_CREATE_BUTTONS = {
  campaign: {
    title: 'Create New Link',
    route: 'newCampaign',
  },
  reward: {
    title: 'Create New Shop Item',
    route: 'newReward',
  },
  massMessage: {
    title: 'Create New Mass Message',
    route: 'newUserMassMessage',
  },
}

export const MONTHS = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sept', 'Oct', 'Nov', 'Dec']

export const STORE_INFO = {
  apple: 'https://apps.apple.com/gb/app/crowdmouth/id1556230270',
  google: 'https://play.google.com/store/apps/details?id=com.crowdmouth.user',
}

export const MENU_OPTIONS = {
  editObject: {
    text: 'Edit',
    icon: 'edit',
  },
  activateObject: {
    text: 'Activate',
    icon: 'activate',
  },
  deactivateObject: {
    text: 'Deactivate',
    icon: 'deactivate',
  },
  deleteObject: {
    text: 'Delete',
    icon: 'delete',
  },
}
