// ? Rules for extra{} object:
// label - Name to display in sidebar
// icon - Icon to display in sidebar
// withLayout - Whether or not to wrap page in usual layout (with header and sidebar)
// isShownInSidebar - Whether or not it is shown in the sidebar
// breadCrumbs - Path keys to show in the breadcrumbs at the top of the page. Leave empty / Do not define to only show current path in crumbs.
//               If the current path doesn't have a `label`, then the current page will not have breadcrumbs.
// ? Note that the keys in extra{} can be used for any reason. And you can add your own keys.

export default {
  login: {
    path: '/login',
    extra: { withLayout: false },
  },
  home: {
    path: '/admin',
    extra: { label: 'Dashboard', icon: 'dashboard' },
  },
  post: {
    path: '/post/:code',
    extra: { label: 'Post', withLayout: false },
  },
  creatorLandingPage: {
    path: '/u/:code',
    extra: { label: 'Creator', withLayout: false },
  },
  profile: '/profile/:username',
  announceLink: {
    path: '/announce/:code',
    extra: { label: 'Link', withLayout: false },
  },
  campaignLink: {
    path: '/campaign/:code',
    extra: { label: 'Link', withLayout: false },
  },
  campaigns: {
    path: '/admin/campaigns',
    extra: {
      group: 'campaign',
      label: 'Links',
      icon: 'megaphone',
    },
  },
  newCampaign: {
    path: '/admin/campaigns/new',
    extra: {
      group: 'campaign',
      label: 'New Link',
      breadCrumbs: ['campaigns'],
    },
  },
  campaign: {
    path: '/admin/campaign/:code',
    extra: { group: 'campaign', label: 'Campaign', breadCrumbs: ['campaigns'] },
  },
  editCampaign: {
    path: '/admin/campaign/:code/edit',
    extra: {
      group: 'campaign',
      label: 'Edit Link',
      breadCrumbs: ['campaigns'],
    },
  },
  userCampaign: {
    path: '/user-campaign/:code',
    extra: { label: 'User Link', withLayout: false },
  },
  rewards: {
    path: '/admin/rewards',
    extra: {
      group: 'reward',
      label: 'Shop',
      icon: 'shop',
    },
  },
  newReward: {
    path: '/admin/rewards/new',
    extra: { group: 'reward', label: 'New Item', breadCrumbs: ['rewards'] },
  },
  reward: {
    path: '/admin/reward/:id',
    extra: { group: 'reward', label: 'Item', breadCrumbs: ['rewards'] },
  },
  editReward: {
    path: '/admin/reward/:id/edit',
    extra: { group: 'reward', label: 'Edit Item', breadCrumbs: ['rewards'] },
  },
  rewardRequest: {
    path: '/reward-request/:code',
    extra: { label: 'Shop Item Request', withLayout: false },
  },
  feed: {
    path: '/admin/feed',
    extra: { label: 'Feed', icon: 'house' },
  },

  // FRM
  userMassMessages: {
    path: '/admin/mass_messaging',
    extra: {
      menuGroup: 'fans',
      group: 'mass_messaging',
      label: 'Mass Messaging ( FRM )',
    },
  },
  fansListing: {
    path: '/admin/fans',
    extra: {
      menuGroup: 'fans',
      group: 'frm',
      label: 'Fan Relationship Manager',
    },
  },
  segmentsListing: {
    path: '/admin/segments',
    extra: {
      menuGroup: 'segments',
      group: 'frm',
      label: 'Fan Group',
    },
  },
  newUserMassMessage: {
    path: '/admin/mass_messaging/new',
    extra: {
      group: 'mass_messaging',
      label: 'New Mass Message',
      breadCrumbs: ['userMassMessages'],
    },
  },
  creditCards: {
    path: '/admin/mass_messaging/credits',
    extra: {
      group: 'mass_messaging',
      label: 'Mass Message',
      breadCrumbs: ['userMassMessages'],
    },
  },
  userMassMessage: {
    path: '/admin/mass_messaging/:id',
    extra: { group: 'mass_messaging', label: 'Mass Message', breadCrumbs: ['userMassMessages'] },
  },
  // End FRM

  // Stripe
  paymentDetails: {
    path: '/admin/payment_details',
    extra: {
      label: 'Billing',
      icon: 'coins',
    },
  },
  // end Stripe

  // earnings
  earnings: {
    path: '/admin/earnings',
    extra: {
      label: 'Earnings',
      icon: 'coins',
    },
  },
  // end earnings

  // Customize Creator Profile
  customize: {
    path: '/admin/customize',
    extra: {
      label: 'Customize',
      icon: 'edit',
    },
  },
  // end Customize Creator Profile

  logout: {
    path: '/admin/logout',
    extra: { group: 'logout', label: 'Logout', icon: 'logout' },
  },

  // * [reserved] [add_new_route]

  updateInfo: {
    path: '/update-info',
  },
  tuneCore: {
    path: '/tunecore/new',
    extra: { label: 'Tunecore', withLayout: false },
  },
}
