import { isEmpty } from 'lodash'

const present = (analytic = {}) => {
  if (isEmpty(analytic)) { return {} }

  return {
    clickSources: analytic?.click_sources,
    newFans: analytic?.fans_stats?.new_fans,
    totalFans: analytic?.fans_stats?.total_fans,
    topLinks: analytic?.top_links,
    totalClicks: analytic?.total_clicks,
  }
}

export default present
