import { Service } from '@sowlutions-tech/next/common/network'
import { API_BASE } from 'global.config'

const DEFAULT_TIMEOUT = 30000

const AXIOS_CONFIG = {
  baseURL: API_BASE,
  timeout:  DEFAULT_TIMEOUT,
  headers: {
    Language: 'en',
  },
}

export default class ProjectService extends Service {
  constructor(props) {
    super({
      ...props,
      axiosConfig: AXIOS_CONFIG,
      extraHeaders: {
        UserRoleID: 'userRoleId',
      },
    })
  }
}
