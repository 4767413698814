import Store, { setObservables } from './Store'
import UserInterestsService from 'network/UserInterestsService.js'
import { observable, action, computed, toJS } from 'mobx'

const OBSERVABLES = {
  _userInterests: { type: observable, defaultValue: [] },
}

class UserInterestsStore extends Store {
  constructor(initialData) {
    super(initialData)
    setObservables(this, initialData, OBSERVABLES)
  }

  service() {
    return new UserInterestsService(this.rootStore)
  }

  setUserInterests(value) {
    this._userInterests = value
  }

  get userInterests() {
    return toJS(this._userInterests)
  }
}

export default UserInterestsStore
