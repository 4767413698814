import { useIntercom } from 'react-use-intercom'

export const updateUser = (user) => {
  const { update } = useIntercom()
  try {
    update({
      user_id: user?.id,
      email: user?.email,
      name: user?.name,
      is_creator: user?.isCreator,
      stripe_id: user?.stripeId,
      created_at: user?.created_at,
    })
  } catch (error) {
    console.log('INTERCOM ERROR', error)
  }
}
