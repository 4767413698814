import React from 'react'
import PropTypes from 'prop-types'

import * as style from './styles'

/**
 * <Describe the CardTitle component here>
 *
 * @component
 * @usage import CardTitle from 'components/CardTitle'
 * @example
 * <Add an example of how CardTitle is used here>
 */
const CardTitle = (props) => <h3 css={style.title(props)} {...props} />

CardTitle.propTypes = {
}

CardTitle.defaultProps = {
}

export default CardTitle
