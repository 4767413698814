import { css } from '@emotion/react'
import { transparentize } from 'polished'
import colors from 'styles/colors'
import { transition, borderRadius, inputPadding } from 'styles/constants'

const INPUT_CONTAINER_MARGIN_BOTTOM = '1rem'
const ERROR_BOTTOM_POSITION = '2.3rem'

export const inputContainer = ({ hasError }) => css`
  position: relative;
  display: flex !important;
  background: none !important;
  margin-bottom: ${INPUT_CONTAINER_MARGIN_BOTTOM};
  flex-direction: column;

  ${hasError && `margin-bottom: ${ERROR_BOTTOM_POSITION};`};

  .input-div {
    width: 100%;
    display: flex;
    flex-direction: row;
  }
`

export const input = ({ withIcon, hasError, theme }) => {
  const isLightTheme = theme === 'light'
  const isDarkTheme = theme === 'dark'
  const defaultColor = isLightTheme ? colors.white : colors.gray
  const placeholderColor = transparentize(0.55, defaultColor)
  const activeColor = isLightTheme ? colors.white : colors.orange
  const textColor = isLightTheme || isDarkTheme ? colors.white : 'initial'
  const background = isDarkTheme ? transparentize(0.9, colors.white) : 'none'
  const errorBackgroundColor = transparentize(0.95, colors.red)
  const errorBorderColor = colors.red

  return css`
    width: inherit;
    background: ${background};
    outline: none;
    border: 0.1rem solid ${placeholderColor};
    border-radius: ${borderRadius};
    padding: ${inputPadding};
    color: ${textColor};
    ${transition};

    ${withIcon && 'padding-left: 2.4rem;'};

    ${hasError && `
      background: ${errorBackgroundColor};
      border-color: ${errorBorderColor};
    `};

    & + #input-icon {
      color: ${placeholderColor};
    }

    &::placeholder, &:-ms-input-placeholder { 
      color: ${placeholderColor};
    }

    &:focus {
      border-color: ${activeColor};

      & + #input-icon {
        color: ${activeColor};
      }
    }
  `
}

export const textArea = css`
  resize: none;
`

export const icon = css`
  position: absolute;
  height: 40%;
  left: 0.75rem;
  top: 0;
  bottom: 0;
  margin: auto;
  ${transition};
`

export const error = css`
  position: absolute;
  bottom: calc((${ERROR_BOTTOM_POSITION} - ${INPUT_CONTAINER_MARGIN_BOTTOM}) * -1);
  color: ${colors.red};
  font-style: italic;
`

export const help = css`
  color: ${colors.pink};
  font-style: italic;
  font-size: 0.75rem;
`

export const selectDiv = css`
  margin-bottom: ${INPUT_CONTAINER_MARGIN_BOTTOM};
`
