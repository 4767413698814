import { isEmpty } from 'lodash'

const present = (role = {}) => {
  if (isEmpty(role)) { return {} }

  return {
    id: role?.id,
    role: role?.role,
  }
}

export default present
