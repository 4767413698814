import { isEmpty } from 'lodash'

const present = (apiResp) => {
  if (!apiResp || isEmpty(apiResp)) { return {} }

  return {
    total: apiResp?.data?.total || 0,
    offset: apiResp?.data?.offset || 0,
    limit: apiResp?.data?.limit || 0,
  }
}

export default present
