import Service from './Service'
import presentApiPagination from 'presenters/apiPaginationPresenter'
import presentReward from 'presenters/rewardPresenter'

export const SERVICES = {
  getAllRewards: {
    type: 'get',
    present: resp => {
      return {
        success: resp?.success,
        data: (resp?.data?.items || []).map(presentReward),
        ...presentApiPagination(resp),
      }
    },
  },
  getReward: {
    type: 'get',
    urlParam: '/:id',
    present: resp => {
      return {
        success: resp?.success,
        data: presentReward(resp?.data || {}),
      }
    },
  },
  createReward: {
    type: 'postWithFormData',
  },
  updateReward: {
    type: 'putWithFormData',
    urlParam: '/:id',
  },
  deleteReward: {
    type: 'delete',
    urlParam: '/:id',
  },
}

/**
 * Instance of Rewards Service.
 *
 * @class
 * @usage `import RewardsService from 'network/RewardsService'`
 */
export default class RewardsService extends Service {
  constructor(props) {
    super(props)
    this.services = SERVICES
  }

  serviceBase() {
    return 'rewards'
  }
}
