import presentInterest from 'presenters/interestPresenter'
import { isEmpty } from 'lodash'

const present = (user = {}) => {
  if (isEmpty(user)) {
    return {}
  }

  return {
    id: user?.id,
    bio: user?.bio,
    name: user?.name,
    subtitle: user?.subtitle,
    email: user?.email,
    image: user?.image?.large,
    coverImage: user?.cover_photo?.large,
    interests: (user?.interests || []).map(presentInterest),
    socials: user?.social_media || [],
    followersCount: user?.followers_count,
    textColor: user?.text_color,
    partnerId: user?.partner_id,
    smsCredits: user?.sms_credits,
    // creator stuff
    profileCustomColor: user?.profile_custom_color,
    profileDefaultTab: user?.profile_default_tab,
    profileEnableCrowdmouthBranding: user?.profile_enable_crowdmouth_branding,

    // if i'm getting the user by someone else, these are usefull
    pointsTowardUser: user?.points_toward_user || 0,
  }
}

export default present
