import Service from './Service'
import presentUserCampaign from 'presenters/userCampaignPresenter'

export const SERVICES = {
  getCampaign: {
    type: 'get',
    urlParam: '/by_code/:code',
    present: resp => {
      return {
        success: resp?.success,
        data: presentUserCampaign(resp?.data || {}),
      }
    },
  },
}

/**
 * Instance of UserCampaigns Service.
 *
 * @class
 * @usage `import UserCampaignsService from 'network/UserCampaignsService'`
 */
export default class UserCampaignsService extends Service {
  constructor(props) {
    super(props)
    this.services = SERVICES
  }

  serviceBase() {
    return 'user_campaigns'
  }
}
