import React from 'react'
import PropTypes from 'prop-types'

import IconsMap from './icons'

import * as style from './styles'

/**
 * Icon
 *
 * All icons should be in SVG format and should have:
 * - A <linearGradiant />
 * - At least one `main-path`. // If the path doesn't have a fill `none` and you want to fill the path
 * - At least one `main-path-stroke`. // If the path has a fill `none` and you want to color the stroke
 * - The svg sttributes: `width="1em" height="1em"`
 *
 * @component
 * @usage `import Icon from 'components/Icon'`
 */
const Icon = ({ icon, color, withGradient, textColor, ...extra }) => {
  const FoundIcon = IconsMap[icon]
  if (!Boolean(FoundIcon)) {
    return null
  }

  return (
    <FoundIcon
      css={style.icon({ color, withGradient, textColor })}
      {...extra}
    />
  )
}

Icon.propTypes = {
  icon: PropTypes.oneOf(Object.keys(IconsMap)).isRequired,
  color: PropTypes.string,
  withGradient: PropTypes.bool,
  textColor: PropTypes.string,
}

Icon.defaultProps = {
  color: '',
  withGradient: false,
  textColor: '',
}

export default Icon
