import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { isNil, kebabCase } from 'lodash'

import * as style from './styles'

const EMPTY_PLACEHOLDER = 'empty-placeholder'

/**
 * <Describe the Select component here>
 *
 * @component
 * @usage import Select from 'components/Select'
 * @example
 * const OPTIONS = [
 *  { key: 'A', label:'A' }
 *  { key: 'B', label:'B' }
 *  { key: 'C', label:'C' }
 * ]
 *
 * <Select placeholder='text' options={OPTIONS} />
 */
const Select = ({ defaultValue, placeholder, options, onChange, ...props }) => {
  const [isValueSelected, setIsValueSelected] = useState(
    defaultValue !== EMPTY_PLACEHOLDER
  )
  const groupName = kebabCase(placeholder)

  const handleOptionClick = (optionKey) => {
    setIsValueSelected(!isNil(optionKey))
    onChange(optionKey)
  }

  return (
    <select
      name={groupName}
      defaultValue={defaultValue}
      onChange={ev => handleOptionClick(ev.target.value)}
      css={style.select(isValueSelected)}
      {...props}
    >
      {
        placeholder && (
          <option
            value={EMPTY_PLACEHOLDER}
            disabled
            hidden
          >
            {placeholder}
          </option>
        )
      }
      {
        options.map(option => (
          <option
            key={option.key}
            value={option.key}
            css={style.option}
          >
            {option.label}
          </option>
        ))
      }
    </select>
  )
}

Select.propTypes = {
  placeholder: PropTypes.string,
  options: PropTypes.array,
  defaultValue: PropTypes.string,
  onChange: PropTypes.func,
}

Select.defaultProps = {
  placeholder: '',
  options: [],
  defaultValue: EMPTY_PLACEHOLDER,
  onChange: (value) => { },
}

export default Select
