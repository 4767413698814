import presentUser from 'presenters/userPresenter'
import presentAttachment from 'presenters/attachmentPresenter'
import { isEmpty } from 'lodash'

const present = (post = {}) => {
  if (isEmpty(post)) { return {} }

  return {
    id: post?.id,
    content: post?.content,
    postType: post?.class_name,
    liked: post?.liked_by_user,
    shareableLink: post?.shareable_link,
    images: (post?.attachments || []).map(presentAttachment),
    timeCreated: post?.created_at_formatted,
    postCode: post?.code,
    user: presentUser(post?.user),
  }
}

export default present
