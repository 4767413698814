import { css } from '@emotion/react'

export const zIndexes = {
  notifications: 200,
  modal: 150,
  top: 100,
  middle: 50,
  bottom: 0,
}

export const GRID_BREAKPOINTS = {
  topRow: { md: 24, lg: 12 },
  centerRow: { xs: 24 },
  bottomRow: { md: 24, lg: 12 },
}

export const HEADER_HEIGHT = '4rem'

export const SIDEBAR_WIDTH = '15rem'

export const transition = css`
  /* transition: 0.25s cubic-bezier(0.25, -0.69, 0.74, 1.63); */
  transition: 0.2s ease-in-out;
`

export const borderRadius = '0.1rem'

export const inputPadding = '0.5rem 0.75rem'
