import Store, { setObservables } from './Store'
import StripeService from '~/network/StripeService.js'
// import { observable, action, computed, toJS } from 'mobx'

const OBSERVABLES = {
  // _configuration: { type: observable, defaultValue: [] },
}

class StripeStore extends Store {
  constructor(initialData) {
    super(initialData)
    setObservables(this, initialData, OBSERVABLES)
  }

  service() {
    return new StripeService(this.rootStore)
  }
}

export default StripeStore
