import Service from './Service'
import presentCurrentUser from 'presenters/currentUserPresenter'

export const SERVICES = {
  getUserData: {
    type: 'get',
    present: resp => {
      return {
        success: resp?.success,
        data: presentCurrentUser(resp?.data || {}),

      }
    },
  },
}

/**
 * Instance of Authentication Service.
 *
 * @class
 * @usage `import Authentication from 'network/Authentication'`
 */
export default class Authentication extends Service {
  constructor(props) {
    super(props)
    this.services = SERVICES
  }

  serviceBase() {
    return 'users'
  }
}
