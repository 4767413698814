import Service from './Service'
// import presentApiPagination from '~/presenters/apiPaginationPresenter'
// import presentReward from '~/presenters/rewardPresenter'

export const SERVICES = {
  createCustomerPortalSession: {
    type: 'post',
    urlParam: '/customer_portal_session',
  },
  createCheckoutSession: {
    type: 'post',
    urlParam: '/checkout_session',
  },
}

/**
 * Instance of UserMassMessages Service.
 *
 * @class
 * @usage `import UserMassMessagesService from '~/network/UserMassMessagesService'`
 */
export default class UserMassMessagesService extends Service {
  constructor(props) {
    super(props)
    this.services = SERVICES
  }

  serviceBase() {
    return 'stripe'
  }
}
