import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import { motion } from 'framer-motion'

import Icon from 'components/Icon'

import * as style from './styles'

const ANIMATION_DOT = {
  animate: { opacity: 0, scale: 2.5 },
  transition: {
    repeat: Infinity,
    repeatType: 'loop',
    ease: 'easeOut',
    duration: 2.5,
  },
}

const Dot = ({ color, content }) => {
  const hasContent = Boolean(content)
  const dotStyles = style.badgeDot({ color, hasContent })

  return (
    <Fragment>
      <motion.div
        css={dotStyles}
        {...ANIMATION_DOT}
      />
      <div css={dotStyles}>
        {content}
      </div>
    </Fragment>
  )
}

/**
 * <Describe the BadgeWrapper component here>
 *
 * @component
 * @usage import BadgeWrapper from 'components/BadgeWrapper'
 * @example
 * <Add an example of how BadgeWrapper is used here>
 */
const BadgeWrapper = ({ icon, isActive, color, content }) => {
  return (
    <span css={style.badgeWrapper}>
      {
        isActive && (
          <Dot
            color={color}
            content={content}
          />
        )
      }
      <Icon
        icon={icon}
        css={style.notificationIcon}
      />
    </span>
  )
}

BadgeWrapper.propTypes = {
  icon: PropTypes.string,
  isActive: PropTypes.bool,
  color: PropTypes.string,
  content: PropTypes.string,
}

BadgeWrapper.defaultProps = {
  icon: '',
  isActive: false,
  color: 'red',
  content: '',
}

export default BadgeWrapper
