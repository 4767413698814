import React from 'react'
import PropTypes from 'prop-types'

import Icon from 'components/Icon'
import Spinner from 'components/Spinner'
import { isNil } from 'lodash'

import * as style from './styles'

// ? Icon used in normal button
const ButtonIcon = ({ icon, isLoading = false }) => {
  const withIcon = Boolean(icon)

  const sharedProps = {
    id: 'button-icon',
    css: style.icon,
  }

  if (isLoading) {
    return (
      <Spinner
        {...sharedProps}
      />
    )
  }

  if (withIcon) {
    return (
      <Icon
        {...sharedProps}
        icon={icon}
      />
    )
  }

  return null
}

/**
 * <Describe the Button component here>
 *
 * @component
 * @usage import Button from 'components/Button'
 * @example
 * <Add an example of how Button is used here>
 */
const Button = ({
  children, type, icon, color, isLoading, isDisabled, isPremiumOnly,
  onClick, borderRadius, justifyContent, ...extra
}) => {
  const isLink = type === 'link'
  const isIconButton = isNil(children)

  if (isLink) {
    return (
      <a css={style.link({ color })} onClick={onClick} {...extra}>
        <ButtonIcon icon={icon} />
        {children}
      </a>
    )
  }

  const sharedProps = {
    disabled: isDisabled || isLoading,
    css: style.button({ color, type, isDisabled, isLoading, isIconButton, borderRadius, justifyContent }),
    onClick,
    ...extra,
  }

  if (isIconButton) {
    return (
      <button {...sharedProps}>
        {isPremiumOnly && <span css={style.premium}>PRO</span>}
        <ButtonIcon icon={icon} isLoading={isLoading} />
      </button>
    )
  }

  return (
    <button {...sharedProps}>
      {isPremiumOnly && <span css={style.premium}>PRO</span>}
      <ButtonIcon icon={icon} isLoading={isLoading} />
      {children}
    </button>
  )
}

Button.propTypes = {
  type: PropTypes.oneOf(['filled', 'outlined', 'inverted', 'link', 'lite']),
  icon: PropTypes.string,
  color: PropTypes.string,
  isLoading: PropTypes.bool,
  isDisabled: PropTypes.bool,
  isPremiumOnly: PropTypes.bool,
  onClick: PropTypes.func,
  borderRadius: PropTypes.string,
  justifyContent: PropTypes.string,
}

Button.defaultProps = {
  type: 'filled',
  icon: '',
  color: 'pink',
  isLoading: false,
  isDisabled: false,
  isPremiumOnly: false,
  borderRadius: '2em',
  justifyContent: 'center',
  onClick: () => { },
}

export default Button
