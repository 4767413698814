import Service from './Service'
import presentRewardTemplate from 'presenters/rewardTemplatePresenter'

export const SERVICES = {
  getAllTemplates: {
    type: 'get',
    present: resp => {
      return {
        success: resp?.success,
        data: (resp?.data?.items || []).map(presentRewardTemplate),
      }
    },
  },
  getTemplate: {
    type: 'get',
    urlParam: '/:id',
  },
}

/**
 * Instance of RewardTemplates Service.
 *
 * @class
 * @usage `import RewardTemplatesService from 'network/RewardTemplatesService'`
 */
export default class RewardTemplatesService extends Service {
  constructor(props) {
    super(props)
    this.services = SERVICES
  }

  serviceBase() {
    return 'reward_templates'
  }
}
