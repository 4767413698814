import { RootStore } from '@sowlutions-tech/next/common/stores'

import AuthStore from './AuthStore'
import ExampleStore from './ExampleStore'
import TestStore from './TestStore'
import CampaignsStore from './CampaignsStore'
import UserCampaignsStore from './UserCampaignsStore'
import UserActionsStore from './UserActionsStore'
import UserPostsStore from './UserPostsStore'
import UsersStore from './UsersStore'
import UserInterestsStore from './UserInterestsStore'
import InterestsStore from './InterestsStore'
import AnalyticsStore from './AnalyticsStore'
import NotificationsStore from './NotificationsStore'
import StatesStore from './StatesStore'
import RewardsStore from './RewardsStore'
import RewardTemplatesStore from './RewardTemplatesStore'
import FeedStore from './FeedStore'
import RewardRequestsStore from './RewardRequestsStore'
import UserMassMessagesStore from './UserMassMessagesStore'
import StripeStore from './StripeStore'
import DataStore from './DataStore'
import InAppPurchaseItemStore from './InAppPurchaseItemStore'
import RevenuesStore from './RevenuesStore'
import UserSegmentsStore from './UserSegmentsStore'
// * [reserved] [import_generated_store]

const STORES = {
  authStore: AuthStore,
  exampleStore: ExampleStore,
  testStore: TestStore,
  campaignsStore: CampaignsStore,
  userCampaignsStore: UserCampaignsStore,
  userActionsStore: UserActionsStore,
  userPostsStore: UserPostsStore,
  usersStore: UsersStore,
  userInterestsStore: UserInterestsStore,
  interestsStore: InterestsStore,
  analyticsStore: AnalyticsStore,
  notificationsStore: NotificationsStore,
  statesStore: StatesStore,
  rewardsStore: RewardsStore,
  rewardTemplatesStore: RewardTemplatesStore,
  feedStore: FeedStore,
  rewardRequestsStore: RewardRequestsStore,
  userMassMessagesStore: UserMassMessagesStore,
  stripeStore: StripeStore,
  dataStore: DataStore,
  inAppPurchaseItemStore: InAppPurchaseItemStore,
  revenuesStore: RevenuesStore,
  userSegmentsStore: UserSegmentsStore,
  // * [reserved] [declare_generated_store]
}

const getRootStore = (initialData) => RootStore.getRootStore(initialData, STORES)

export {
  getRootStore,
}
