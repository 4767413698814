import Service from './Service'
// todo: Delete

export const SERVICES = {
  createExample: {
    type: 'post',
    extraParams: {},
    extraHeaders: {},
    present: (resp) => resp,
  },
  getExample: {
    type: 'get',
    urlParam: '/:id',
    extraParams: {},
    extraHeaders: {},
    present: (resp) => resp,
  },
  updateExample: {
    type: 'put',
    urlParam: '/:id',
    extraParams: {},
    extraHeaders: {},
    present: (resp) => resp,
  },
  deleteExample: {
    type: 'delete',
    urlParam: '/:id',
    extraParams: {},
    extraHeaders: {},
    present: (resp) => resp,
  },
}

/**
 * Instance of Example Service.
 *
 * @class
 * @usage `import ExampleService from 'network/ExampleService'`
 */
export default class ExampleService extends Service {
  constructor(props) {
    super(props)
    this.services = SERVICES
  }

  serviceBase() {
    return 'examples'
  }
}
