import FingerprintJS from '@fingerprintjs/fingerprintjs'
import { StorageHelper } from '@sowlutions-tech/next/common/helpers'
import { IsServer } from '@sowlutions-tech/next/common/utils'

const STORAGE_KEY = 'cwdmth-fingerprint'

export const generateImei = async () => {
  const currentUID = StorageHelper.storageGet(STORAGE_KEY)

  if (!currentUID) {
    const fp = await FingerprintJS.load()
    const result = await fp.get()
    const visitorId = result.visitorId
    StorageHelper.storageSet({ [STORAGE_KEY]: visitorId })
  }
}

export const imei = () => {
  if (IsServer) {
    return null
  }

  return StorageHelper.storageGet(STORAGE_KEY)
}
