import { isEmpty } from 'lodash'

const present = (interest = {}) => {
  if (isEmpty(interest)) { return {} }

  if (interest.interest) {
    interest = interest.interest
  }

  return {
    id: interest?.id,
    label: interest?.name,
    sortOrder: interest?.sort_order,
  }
}

export default present
