import Store, { setObservables } from './Store'
import FeedService from 'network/FeedService.js'
import { observable, action, computed, toJS } from 'mobx'

const OBSERVABLES = {
  _allActivities: { type: observable, defaultValue: [] },
}

class FeedStore extends Store {
  constructor(initialData) {
    super(initialData)
    setObservables(this, initialData, OBSERVABLES)
  }

  service() {
    return new FeedService(this.rootStore)
  }

  async getAllActivities(params) {
    return await this.accessStoreCache({
      api: 'getAllActivities',
      params,
      observable: 'allActivities',
      setter: 'setAllActivities',
    })
  }

  setAllActivities(value) {
    this._allActivities = value
  }

  get allActivities() {
    return toJS(this._allActivities)
  }
}

export default FeedStore
