import Store, { setObservables } from './Store'
import { observable, action, computed, toJS } from 'mobx'

const OBSERVABLES = {
  _notifications: { type: observable, defaultValue: {} },
  notificationsAsArray: { type: computed },
  addNotification: { type: action },
  removeNotification: { type: action },
}

class NotificationsStore extends Store {
  constructor(initialData) {
    super(initialData)
    setObservables(this, initialData, OBSERVABLES)
  }

  // ? Types: 'info', 'success', 'warning', 'error', 'default'
  addNotification({ type = 'default', message = '<Empty>' } = {}) {
    const timeStamp = new Date().getTime()
    this._notifications[timeStamp] = {
      id: timeStamp,
      type,
      message,
    }
  }

  removeNotification(id) {
    delete this._notifications[id]
  }

  get notifications() {
    return toJS(this._notifications)
  }

  get notificationsAsArray() {
    return Object.values(toJS(this._notifications))
  }
}

export default NotificationsStore
