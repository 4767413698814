import colors from 'styles/colors'

export const SEO = {
  title: 'Crowdmouth',
  description: 'Crowdmouth',
  robots: 'noodp',
  keywords: 'crowdmouth',
  url: 'https://cmth.co',
  urlPath: '', // Appends to the above URL
  image: 'https://app.crowdmouth.com/static/logo.svg',
  tileColor: colors.pink,
  tileImage: 'https://app.crowdmouth.com/static/logo.svg',
  color: colors.pink,
}

export const OG = {
  locale: 'en_US',
  type: 'website',
  title: SEO.title,
  description: SEO.description,
  url: SEO.url,
  siteName: SEO.title,
  image: SEO.image,
}

export const TWITTER = {
  card: 'summary',
  title: SEO.title,
  description: SEO.description,
  url: SEO.url,
  image: SEO.image,
}
