import Store, { setObservables } from './Store'
import InterestsService from 'network/InterestsService.js'
import { observable, toJS } from 'mobx'

const OBSERVABLES = {
  _interests: { type: observable, defaultValue: [] },
}

class InterestsStore extends Store {
  constructor(initialData) {
    super(initialData)
    setObservables(this, initialData, OBSERVABLES)
  }

  service() {
    return new InterestsService(this.rootStore)
  }

  setInterests(value) {
    this._interests = value
  }

  get Interests() {
    return toJS(this._interests)
  }
}

export default InterestsStore
