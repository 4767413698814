import Store, { setObservables } from './Store'
import RewardsService from 'network/RewardsService.js'
import { observable, action, computed, toJS } from 'mobx'

const OBSERVABLES = {
  _allRewards: { type: observable, defaultValue: [] },
}

class RewardsStore extends Store {
  constructor(initialData) {
    super(initialData)
    setObservables(this, initialData, OBSERVABLES)
  }

  service() {
    return new RewardsService(this.rootStore)
  }

  async getAllRewards(params) {
    return await this.accessStoreCache({
      api: 'getAllRewards',
      params,
      observable: 'allRewards',
      setter: 'setAllRewards',
    })
  }

  setAllRewards(value) {
    this._allRewards = value
  }

  get allRewards() {
    return toJS(this._allRewards)
  }
}

export default RewardsStore
