import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'

import { useLayer, useHover, Arrow } from 'react-laag'
import { motion, AnimatePresence } from 'framer-motion'

import * as style from './styles'
import colors from 'styles/colors'

const TOOLTIP_COLOR = 'pink'

const TRIGGER_ACTIONS = {
  none: '', // Always open
  hover: 'hover', // Open on hover
  click: 'click', // Open on click
}

const ANIMATIONS = {
  bounce: {
    initial: { opacity: 0, scale: 0.25 },
    animate: { opacity: 1, scale: 1 },
    exit: { opacity: 0, scale: 0 },
    transition: { type: 'spring', bounce: 0.75, duration: 0.75 },
  },
  grow: {
    initial: { opacity: 0, scale: 0.25 },
    animate: { opacity: 1, scale: 1 },
    exit: { opacity: 0, scale: 0 },
    transition: { type: 'tween', duration: 0.15 },
  },
}

const DELAY_HOVER_ENTER = 100
const DELAY_HOVER_LEAVE = 300

const isReactText = (children) => {
  return ['string', 'number'].includes(typeof children)
}

/**
 * Tooltips for almost any element. Supports various trigger actions.
 *
 * @component
 * @usage import Tooltip from 'components/Tooltip'
 * @example
 * <Tooltip content='I am a tooltip' trigger='button'>
 *  <button onClick={() => console.log('I will still performs onClick actions.')}>
 *    Click me for a tooltip!
 *  </button>
 * </Tooltip>
 */
const Tooltip = ({ children, content, trigger, isDisplayed, onHoverEnter, onHoverLeave, animation }) => {
  const hasNoTrigger = trigger === TRIGGER_ACTIONS.none
  const shouldTriggerOnHover = trigger === TRIGGER_ACTIONS.hover
  const shouldTriggerOnClick = trigger === TRIGGER_ACTIONS.click
  const [isBeingHovered, hoverProps] = useHover({ delayEnter: DELAY_HOVER_ENTER, delayLeave: DELAY_HOVER_LEAVE })
  const [hasClicked, setHasClicked] = useState(false)

  const isOpen = hasNoTrigger || shouldTriggerOnHover && isBeingHovered || hasClicked

  if(!isDisplayed) {
    return <div>{children}</div>
  }

  useEffect(() => {
    if (shouldTriggerOnHover) {
      if (isBeingHovered) {
        setTimeout(onHoverEnter, DELAY_HOVER_ENTER)
      } else if (!isBeingHovered) {
        setTimeout(onHoverLeave, DELAY_HOVER_LEAVE)
      }
    }
  }, [isBeingHovered])

  const { triggerProps, layerProps, arrowProps, renderLayer } = useLayer({
    isOpen,
    placement: 'top-center',
    auto: true,
    triggerOffset: 8,
    onOutsideClick: () => setHasClicked(false),
    // onDisappear: () => console.log('gone'),
  })

  let triggerElement
  if (isReactText(children)) {
    triggerElement = (
      <span {...triggerProps} {...hoverProps}>
        {children}
      </span>
    )
  } else {
    triggerElement = React.cloneElement(children, {
      ...triggerProps,
      ...hoverProps,
      onClick: shouldTriggerOnClick
        ? () => {
          children.props.onClick()
          setHasClicked(prevState => !prevState)
        }
        : children.props.onClick,
    })
  }

  return (
    <>
      {triggerElement}
      {renderLayer(
        <AnimatePresence>
          {isOpen && (
            <motion.div
              css={style.tooltip({ color: TOOLTIP_COLOR })}
              {...ANIMATIONS[animation]}
              {...layerProps}
            >
              {content}
              <Arrow
                {...arrowProps}
                backgroundColor={colors[TOOLTIP_COLOR]}
                borderColor={colors[TOOLTIP_COLOR]}
                borderWidth={0}
                size={6}
              />
            </motion.div>
          )}
        </AnimatePresence>
      )}
    </>
  )
}

Tooltip.propTypes = {
  content: PropTypes.string,
  trigger: PropTypes.oneOf(Object.values(TRIGGER_ACTIONS)),
  isDisplayed: PropTypes.bool,
  onHoverEnter: PropTypes.func,
  onHoverLeave: PropTypes.func,
  animation: PropTypes.oneOf(Object.keys(ANIMATIONS)),
}

Tooltip.defaultProps = {
  content: '',
  trigger: '',
  isDisplayed: false,
  onHoverEnter: () => {},
  onHoverLeave: () => {},
  animation: 'bounce',
}

export default Tooltip
