import { css } from '@emotion/react'
import colors from 'styles/colors'

export const container = css`
  background-color: ${colors.white};
  border-radius: 1rem;
  display: flex;
  flex-direction: column;
  padding: 2rem;
  max-width: 800px;
  margin: auto;
  margin-top: 2rem;

  .card {
    padding: 1.5rem;
    border-radius: 1rem;
  }

  .row {
    flex: 1;
    flex-direction: row;
    display: flex;

    .column {
      flex: 1;
      padding: 0.25rem;
      display: flex;
      flex-direction: column;
    }

    .column-45 {
      flex: 0.45;
    }
    .column-55 {
      flex: 0.55;
    }
  }

  .title-row {
    margin-top: 1rem;
  }

  .info-row {
    display: flex;
    flex-direction: column;
    background-color: #2e2b2b;
    margin-top: 2rem;
    padding: 1.5rem;
    border-bottom-left-radius: 0.8rem;
    border-bottom-right-radius: 0.8rem;
  }

  img.cm-pro {
    max-width: 10rem;
    padding-bottom: 0.8rem;
  }

  .title {
    font-size: 2rem;
    font-weight: 900;
    line-height: 2.4rem;
    letter-spacing: 0em;
    text-align: left;
    padding: 0;
    margin: 0;
  }
  .description {
    font-size: 0.8rem;
    font-weight: 400;
    line-height: 1rem;
    letter-spacing: 0em;
    text-align: left;
  }

  .white-text {
    color: ${colors.white};
  }

  .multi-colored-title {
    margin: 0;
    margin-bottom: 0.5rem;
    padding: 0;
    font-size: 1rem;
    font-weight: 700;
    line-height: 1.2rem;
    letter-spacing: 0em;
    text-align: center;

    span {
      color: ${colors.pink};
    }
  }

  .upgrade-container {
    padding-top: 1rem !important;
    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: column;

    .multi-colored-title {
      color: white;
    }

    button {
      width: 100%;
    }
  }

  .video {
    cursor: pointer;
    height: 100%;
    width: 100%;
    background-image: url(/static/cm-pro_video-placeholder.jpg);
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
    border-radius: 1rem;
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;

    .play-icon {
      padding-right: 1rem;
      padding-bottom: 1rem;
      display: flex;
      align-content: flex-end;
    }
  }

  .features-table {
    thead {
      tr {
        th {
          color: ${colors.white};
          font-size: 0.7rem;
          font-weight: 700;
          line-height: 0.9rem;
          letter-spacing: 0em;
          text-align: center;
          padding: 0.3rem;

          &.first-text {
            border-left: 1px solid #5e5e5e;
            border-top: 1px solid #5e5e5e;
          }
          &.second-text {
            border-right: 1px solid #5e5e5e;
            border-top: 1px solid #5e5e5e;
          }
          &.pro {
            background: ${colors.pink};
          }
        }
      }
    }

    tbody {
      tr {
        td {
          background-color: black;
          padding: 0.2rem;
          color: ${colors.white};
          font-size: 0.8rem;
          font-weight: 400;
          line-height: 1rem;
          letter-spacing: 0em;
          border-top: 1px solid #5e5e5e;

          &.center {
            text-align: center;
          }

          &:first-child {
            border-left: 1px solid #5e5e5e;
          }
          &:last-child {
            border-right: 1px solid #5e5e5e;
          }
        }

        &:last-of-type {
          td {
            border-bottom: 1px solid #5e5e5e;
          }
        }
      }
    }
  }
`
