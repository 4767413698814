import { css } from '@emotion/react'
import { transparentize } from 'polished'
import colors from 'styles/colors'
import { zIndexes } from 'styles/constants'

export const pageLoader = css`
  position: fixed;
  display: flex;
  flex-direction: column;
  align-items: center;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  justify-content: center;
  min-height: 50vh;
  height: 100%;
  font-size: large;
  color: ${colors.blue};
  background: ${transparentize(0.15, colors.white)};
  z-index: ${zIndexes.notifications};
`

export const pageSpinner = css`
  font-size: 7rem;
  margin-bottom: 1rem;
`
