import Store, { setObservables } from './Store'
import RevenuesService from '~/network/RevenuesService.js'
import { observable, action, computed, toJS } from 'mobx'

const OBSERVABLES = {
}

class RevenuesStore extends Store {
  constructor(initialData) {
    super(initialData)
    setObservables(this, initialData, OBSERVABLES)
  }

  service() {
    return new RevenuesService(this.rootStore)
  }
}

export default RevenuesStore
