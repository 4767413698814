import React from 'react'
import PropTypes from 'prop-types'

import Spinner from 'components/Spinner'
import phrases from './phrases'

import * as style from './styles'

/**
 * <Describe the PageLoader component here>
 *
 * @component
 * @usage import PageLoader from 'components/PageLoader'
 * @example
 * <Add an example of how PageLoader is used here>
 */
const PageLoader = ({ isShown }) => {
  if (!isShown) {
    return null
  }

  const randomPhrase = phrases[Math.floor(Math.random() * phrases.length)]

  return (
    <div css={style.pageLoader}>
      <Spinner css={style.pageSpinner} />
      {randomPhrase}
    </div>
  )
}

PageLoader.propTypes = {
  isShown: PropTypes.bool,
}

PageLoader.defaultProps = {
  isShown: false,
}

export default PageLoader
