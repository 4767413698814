import { css } from '@emotion/react'
import colors from 'styles/colors'
import { mqMax } from 'styles/mixins/mediaQueries'

export const overlay = css`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: black;
  background-color: rgba(0, 0, 0, 0.75);
`
export const mainContainer = css`
  position: fixed;
`
export const modal = css`
  position: fixed;
  top: 3em;
  left: 40%;
  min-width: 30rem;
  border: 1px solid black;
  height: fit-content;
  background-color: ${colors.white};
  border-radius: 10px;

  ${mqMax.sm} {
    transform: translate(-50%, 0%);
    min-width: 21rem;
    display: flex;
    flex-direction: column;
  }

  ${mqMax.md} {
    transform: translate(-30%, 0%);
  }
  .popup-arrow {
    display: none;
  }
`

export const modalHeader = css`
  background: linear-gradient(to right, ${colors.pink}, ${colors.orange});
  color: ${colors.white};
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  height: 4rem;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;

  h1 {
    color: ${colors.white};
  }

  .close {
    position: absolute;
    right: 0.5rem;
    top: 0.1rem;
    background: transparent;
    border: none;
    color: ${colors.white};
    font-size: 2rem;
    cursor: pointer;
  }

  .close:hover {
    transform: scale(1.2);
  }
`

export const modalContent = css`
  background: ${colors.white};
  min-height: 15rem;
  display: flex;
  justify-content: center;
  padding: 2rem;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;

  .badges {
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    gap: 1em;
    padding: 2rem 0;

    & div {
      min-width: 7rem;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
  }

  .mid-line {
    margin: 2rem 1rem;
    color: ${colors.gray};
    border-radius: 5px;
  }
`

export const details = css`
  padding: 2rem 0;

  .details-header {
    margin: 0;
    margin-bottom: 0.5rem;
    font-size: 1.5rem;
    color: #000;
  }

  .details-subheader {
    font-size: 1rem;
    margin: 0;
    color: #000;
  }

  .details-p {
    margin: 0;
    font-size: 0.8rem;
    margin-bottom: 0.8rem;
    color: #000;
  }

  #link {
    text-decoration: none;
    color: ${colors.pink};
    background: transparent;
    border: none;
    cursor: pointer;
  }
`

export const btn = css`
  text-align: center;
  margin-bottom: 1rem;
`
