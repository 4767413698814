import Store, { setObservables } from './Store'
import ExampleService from 'network/ExampleService.js'
import { observable, toJS } from 'mobx'

const OBSERVABLES = {
  _observableOne: { type: observable, defaultValue: '' },
  _observableTwo: { type: observable, defaultValue: '' },
}

class ExampleStore extends Store {
  constructor(initialData = {}) {
    super(initialData)
    setObservables(this, initialData, OBSERVABLES)
  }

  // ? To access service Apis: exampleStore.api().getExample({ id: 1 })
  // ? If you would like to add side effects or other actions (such as
  // ? storing the resp in a store observable), you can create custom
  // ? function, in the store, that will call the API.
  service() {
    return new ExampleService(this.rootStore)
  }

  setObservableOne(value) {
    this._observableOne = value
  }

  setObservableTwo(value) {
    this._observableTwo = value
  }

  get observableOne() {
    return toJS(this._observableOne)
  }

  get observableTwo() {
    return toJS(this._observableTwo)
  }

  returnSomething() {
    return 'Something from ExampleStore'
  }
}

export default ExampleStore
