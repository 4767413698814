import Service from './Service'
// import presentApiPagination from '~/presenters/apiPaginationPresenter'
// import presentReward from '~/presenters/rewardPresenter'

export const SERVICES = {
  getByReward: {
    type: 'get',
    urlParam: '/by_reward',
    present: (resp) => {
      return {
        ...resp,
        data: {
          items: resp.data?.rewards,
          total: (resp.data?.rewards || []).length,
        },
        initialData: resp.data,
      }
    },
  },
}

/**
 * Instance of RevenuesServices Service.
 *
 * @class
 * @usage `import RevenuesServicesService from '~/network/RevenuesServicesService'`
 */
export default class RevenuesServicesService extends Service {
  constructor(props) {
    super(props)
    this.services = SERVICES
  }

  serviceBase() {
    return 'revenues'
  }
}
