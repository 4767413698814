import Service from './Service'
import presentRewardRequests from 'presenters/rewardRequestsPresenter'

export const SERVICES = {
  getAllRewardsRequested: {
    type: 'get',
    // present: resp => {
    //   return {
    //     success: resp?.success,
    //     data: (resp?.data?.items || []).map(presentRewardRequests),
    //   }
    // },
  },
  getRewardRequested: {
    type: 'get',
    urlParam: '/by_code/:code',
    present: resp => {
      return {
        success: resp?.success,
        data: presentRewardRequests(resp?.data),
      }
    },
  },
  fulfillRewardRequest: {
    type: 'postWithFormData',
    urlParam: '/:id/fulfil',
  },
  createRewardRequest: {
    type: 'post',
  },

  getStripeData: {
    type: 'post',
    urlParam: '/:id/stripe_data',
  },
  validatePayment: {
    type: 'post',
    urlParam: '/:id/validate_payment',
  },
  cancelPayment: {
    type: 'post',
    urlParam: '/:id/cancel_payment',
  },
}

/**
 * Instance of RewardRequests Service.
 *
 * @class
 * @usage `import RewardRequestsService from 'network/RewardRequestsService'`
 */
export default class RewardRequestsService extends Service {
  constructor(props) {
    super(props)
    this.services = SERVICES
  }

  serviceBase() {
    return 'reward_requests'
  }
}
