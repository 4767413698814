/* eslint-disable no-nested-ternary */
import React, { useState, Fragment, useEffect } from 'react'
import PropTypes from 'prop-types'
import { observer } from 'mobx-react'
import { router } from 'lib/router'

import Header from 'components/Header'
import Sidebar from 'components/Sidebar'
import Breadcrumbs from 'components/Breadcrumbs'
import PageLoader from 'components/PageLoader'
import ToastController from 'components/Toast'
import PromptModal from 'components/PromptModal'
import { Beforeunload } from 'react-beforeunload'
import { updateUser } from '~/helpers/intercomHelper'
import TuneCore from 'pages/tunecore/new.page'

import * as style from './styles.js'

const StandardLayout = ({ rootStore, children, currentRoute }) => {
  const { notificationsStore, statesStore, authStore } = rootStore
  const { notificationsAsArray } = notificationsStore
  const { isLoading, pageTitle, isDataWritten, routeSelected } = statesStore
  const [isOpen, setIsOpen] = useState(false)
  updateUser(authStore.currentUser)
  const user = authStore?.currentUser
  const userStatus = user?.premiumCreator

  useEffect(() => {
    if (user) {
      if (window.location.pathname.includes('announce')) {
        return
      }
      if (user.premiumCreator === false) {
        router.replace(router.routes.tuneCore)
      }
    }
  }, [userStatus])

  if (user && userStatus === undefined) {
    return <div />
  }

  const childrenBare = (
    <Fragment>
      <PageLoader isShown={isLoading} />
      {children}
      <ToastController
        toasts={notificationsAsArray}
        onOpen={(id) => notificationsStore.removeNotification(id)}
      />
    </Fragment>
  )

  if (currentRoute?.extra?.withLayout === false) {
    return childrenBare
  }

  const handleRouting = () => {
    router.push(router.routes[routeSelected])
    statesStore.setRouteSelected('')
    statesStore.setIsDataWritten(false)
    setIsOpen(false)
  }

  if (!user) {
    // User not authenticated
    return (
      <section css={style.layout}>
        <Header
          user={authStore.currentUser}
          isPremium={authStore.premiumCreator}
          authStore={authStore}
          rootStore={rootStore}
        />
        {isDataWritten && (
          <Beforeunload onBeforeunload={(event) => event.preventDefault()} />
        )}
        <main css={style.main}>
          <PromptModal
            isOpen={isOpen}
            setIsOpen={setIsOpen}
            isLoading={isLoading}
            handleClick={handleRouting}
            text='Are you sure you want to leave this page?'
            comment='You have unsaved data...'
          />
          <Sidebar
            currentRoute={currentRoute}
            setRouteSelected={(value) => statesStore.setRouteSelected(value)}
            setIsOpen={setIsOpen}
            isDataWritten={isDataWritten}
            user={authStore.currentUser}
          />
          <div css={style.childrenContainer}>
            <Breadcrumbs
              crumbs={currentRoute?.extra?.breadCrumbs}
              currentRoute={currentRoute}
              pageTitle={pageTitle}
            />
            {childrenBare}
          </div>
        </main>
      </section>
    )
  } else if (user?.premiumCreator) {
    // User is premium, show regular pages
    return (
      <section css={style.layout}>
        <Header
          user={authStore.currentUser}
          isPremium={authStore.premiumCreator}
          authStore={authStore}
          rootStore={rootStore}
        />
        {isDataWritten && (
          <Beforeunload onBeforeunload={(event) => event.preventDefault()} />
        )}
        <main css={style.main}>
          <PromptModal
            isOpen={isOpen}
            setIsOpen={setIsOpen}
            isLoading={isLoading}
            handleClick={handleRouting}
            text='Are you sure you want to leave this page?'
            comment='You have unsaved data...'
          />
          <Sidebar
            currentRoute={currentRoute}
            setRouteSelected={(value) => statesStore.setRouteSelected(value)}
            setIsOpen={setIsOpen}
            isDataWritten={isDataWritten}
            user={authStore.currentUser}
          />
          <div css={style.childrenContainer}>
            <Breadcrumbs
              crumbs={currentRoute?.extra?.breadCrumbs}
              currentRoute={currentRoute}
              pageTitle={pageTitle}
            />
            {childrenBare}
          </div>
        </main>
      </section>
    )
  } else if (!user?.premiumCreator) {
    return <TuneCore />
  } else {
    // Regular non-premium user without partnerId, show regular pages
    return <TuneCore />
  }
}

StandardLayout.propTypes = {}

StandardLayout.defaultProps = {}

export default observer(StandardLayout)
