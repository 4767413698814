import React from 'react'
import PropTypes from 'prop-types'

import * as style from './styles'

/**
 * <Describe the Title component here>
 *
 * @component
 * @usage import Title from 'components/Title'
 * @example
 * <Add an example of how Title is used here>
 */
const Title = ({ children, ...extra }) => {
  return (
    <h1 css={style.title} {...extra}>
      {children}
    </h1>
  )
}

Title.propTypes = {
}

Title.defaultProps = {
}

export default Title
