import Service from './Service'

export const SERVICES = {
  getCountries: {
    type: 'get',
    urlParam: '/countries',
  },
  getStates: {
    type: 'get',
    urlParam: '/states',
  },
  getCities: {
    type: 'get',
    urlParam: '/cities',
  },
  getCity: {
    type: 'get',
    urlParam: '/cities/:id',
  },
}

/**
 * Instance of Data Service.
 *
 * @class
 * @usage `import DataService from '~/network/DataService'`
 */
export default class DataService extends Service {
  constructor(props) {
    super(props)
    this.services = SERVICES
  }

  serviceBase() {
    return 'data'
  }
}
