import Store, { setObservables } from './Store'
import UserPostsService from 'network/UserPostsService.js'
import { observable, action, computed, toJS } from 'mobx'

class UserPostsStore extends Store {
  constructor(initialData) {
    super(initialData)
  }

  service() {
    return new UserPostsService(this.rootStore)
  }
}

export default UserPostsStore
