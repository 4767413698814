import React from 'react'
import PropTypes from 'prop-types'

import CardTitle from 'components/CardTitle'
import CardSubtitle from 'components/CardSubtitle'

import * as style from './styles'

/**
 * <Describe the Card component here>
 *
 * @component
 * @usage import Card from 'components/Card'
 * @example
 * <Add an example of how Card is used here>
 */
const Card = ({ children, title, subtitle, hasMenu, ...extra }) => {
  return (
    <div css={style.card} {...extra}>
      {
        title && <CardTitle hasMenu={hasMenu}>{title}</CardTitle>
      }
      {
        subtitle && <CardSubtitle>{subtitle}</CardSubtitle>
      }
      {children}
    </div>
  )
}

Card.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
}

Card.defaultProps = {
  title: '',
  subtitle: '',
}

export default Card
