export default [
  'This is what we get for hiring monkeys to write our code',
  'Generating witty dialog...',
  'Swapping time and space...',
  'Spinning violently around the y-axis...',
  'Tokenizing real life...',
  'Bending the spoon...',
  'There is no spoon. Because we are not done loading it',
  'We need a new fuse...',
  'I\'m going as fast as I can!',
  'Just gimme a minute, will ya?',
  'This is only going to hurt... A lot',
  'Would you prefer chicken, steak, or tofu?',
  '(Pay no attention to the man behind the curtain)',
  '*Elevator music*',
  'Would you like fries with that?',
  'At least you\'re not on hold...',
  'Hum something loud while others stare',
  'You\'re not in Kansas any more.',
  'The server is powered by a lemon and two electrodes',
  'Someone at the lemon',
  'We don\'t really do much around here, so this is going to take a while...',
  'We\'re testing your patience',
  'Why don\'t you order a sandwich?',
  'Moving the satellites into position...',
  'It\'s still faster than you could draw it',
  'The last time I tried this the monkey didn\'t survive. Let\'s hope it works better this time',
  'The other loading screen is much faster.',
  '(Insert quarter)',
  'Press START to play',
  'Are we there yet?',
  'Have you lost weight?',
  'Just count to 10',
  'Why so serious?',
  'Counting backwards from Infinity',
  'Don\'t panic...',
  'Do not run! We are your friends!',
  'Do you come here often?',
  'Warning: Don\'t set yourself on fire',
  'We\'re making you a cookie',
  'Spinning the wheel of fortune...',
  'Loading the enchanted bunny...',
  'Computing chance of success',
  'I\'m sorry Dave, I can\'t do that.',
  'Looking for exact change',
  'All your web browser are belong to us',
  'I feel like im supposed to be loading something...',
  'What was I doing again?',
  'Let me think...',
  'What do you call 8 Hobbits? A Hobbyte.',
  'Is this Windows?',
  'Nice place ya got.',
  'Adjusting flux capacitor...',
  'Please wait until the sloth starts moving.',
  'Don\'t break your screen yet!',
  'I swear it\'s almost done.',
  'Let\'s take a mindfulness minute...',
  'Inhale. Hold. Exhale.',
  'Unicorns are at the end of this road, I promise.',
  'Listening for the sound of one hand clapping...',
  'Putting the icing on the cake. The cake is not a lie...',
  'Cleaning off the cobwebs...',
  'Making sure all the i\'s have dots...',
  'Our servers run on a dialup connection',
  'We need more dilithium crystals',
  'Where did all the internets go',
  'Connecting Neurotoxin Storage Tank...',
  'Granting wishes...',
  'Time flies when you’re having fun.',
  'Get some coffee and come back in ten minutes, I\'m busy...',
  'Spinning the hamster...',
  '99 bottles of beer on the wall..',
  'Stay awhile and listen...',
  'You shall not pass! Yet..',
  'Load it and they will come',
  'Convincing AI not to turn evil..',
  'Your left thumb points to the right and your right thumb points to the left.',
  'Wait, do you smell something burning?',
  'When nothing is going right, go left!',
  'Why are they called apartments if they are all stuck together?',
  'I think I am, therefore, I am. I think.',
  'Constructing additional pylons...',
  'We are not liable for any broken screens as a result of waiting.',
  'Have you tried turning it off and on again?',
  'If you type Google into Google you can break the internet',
  'Didn\'t know paint dried so quickly.',
  'I\'m going to walk the dog',
  'Dividing by zero...',
  'If I’m not back in five minutes, just wait longer.',
  'Some days, you just can’t get rid of a bug!',
  'We’re going to need a bigger boat.',
  'Cracking military-grade encryption...',
  'Simulating traveling salesman...',
  'Twiddling thumbs...',
  'Laughing at your pictures-I mean, loading...',
  'Sending data to NSA-I mean, our servers.',
  'Please wait while the intern refills his coffee.',
  'Please hold on as we reheat our coffee',
  'Kindly hold on as we convert this bug to a feature...',
  'Winter is coming...',
  'The server is distracted by cat videos',
  'Finding someone to hold my beer',
  'Let\'s hope it\'s worth the wait',
  'Whatever you do, don\'t look behind you...',
  'Please wait... Consulting the manual...',
  'Loading funny message...',
  'Feel free to spin in your chair',
  'What\'s this button do?',
  'Downloading more RAM...',
  'Updating to Windows Vista...',
  'When was the last time you dusted around here?',
  'Optimizing the optimizer...',
  'Updating Updater...',
  'Downloading Downloader...',
  'Debugging Debugger...',
  'Running swag sticker detection...',
  'Never let a computer know you\'re in a hurry.',
  'Shovelling coal into the server',
  'How about this weather, eh?',
  'Reading Terms and Conditions for you.',
  'Live long and prosper.',
  'There is no cow level, but there\'s a goat one!',
  'Running with scissors...',
  'You may call me Steve.',
  'You seem like a nice person...',
  'Coffee at my place, tommorow at 10A.M. - Don\'t be late!',
  'Work, work, work...',
  'Patience! This is difficult, you know...',
  'Discovering new ways of making you wait...',
  'Your time is very important to us. Please wait while we ignore you...',
  'Time flies like an arrow; fruit flies like a banana',
  'Sooooo... Have you seen my vacation photos yet?',
  'Still faster than Windows update',
  'Please wait while the minions do their work',
  'Grabbing extra minions',
  'Doing the heavy lifting',
  'Waking up the minions',
  'You are number 001782 in the queue',
  'Please wait while we serve other customers...',
  'Our premium plan is faster',
]
