import Service from './Service'
import presentApiPagination from 'presenters/apiPaginationPresenter'
import presentFan from 'presenters/fanPresenter'
import presentUser from 'presenters/userPresenter'
import presentCurrentUser from 'presenters/currentUserPresenter'

export const SERVICES = {
  getByUsername: {
    type: 'get',
    urlParam: '/by_username',
    present: (resp) => {
      return {
        success: resp?.success,
        data: presentUser(resp?.data || {}),
      }
    },
  },
  updateUser: {
    type: 'putWithFormData',
    present: (resp) => {
      return {
        success: resp?.success,
        data: presentUser(resp?.data || {}),
        message: resp?.message,
      }
    },
  },
  getTopFans: {
    type: 'get',
    urlParam: '/:id/top_fans',
    present: (resp) => {
      return {
        success: resp?.success,
        data: (resp?.data?.items || []).map(presentFan),
        ...presentApiPagination(resp),
      }
    },
  },
  followUser: {
    type: 'post',
    urlParam: '/:id/follow',
  },
  creatorCreatorRole: {
    type: 'put',
    urlParam: '/creator_signup',
    present: (resp) => {
      return {
        success: resp?.success,
        data: presentCurrentUser(resp?.data || {}),
        message: resp?.message,
      }
    },
  },
  requestOtp: {
    type: 'post',
    urlParam: '/request_otp',
  },
  validateOtp: {
    type: 'post',
    urlParam: '/validate_otp',
    present: (resp) => {
      return {
        success: resp?.success,
        data: presentCurrentUser(resp?.data || {}),
        message: resp?.message,
      }
    },
  },
  facebookSignin: {
    type: 'post',
    urlParam: '/../authentication/facebook_signin',
    present: (resp) => {
      return {
        success: resp?.success,
        data: presentCurrentUser(resp?.data || {}),
        message: resp?.message,
      }
    },
  },
  googleSignin: {
    type: 'post',
    urlParam: '/../authentication/google_signin',
    present: (resp) => {
      return {
        success: resp?.success,
        data: presentCurrentUser(resp?.data || {}),
        message: resp?.message,
      }
    },
  },
  createCard: {
    type: 'post',
    urlParam: '/cards',
  },
  deleteCard: {
    type: 'delete',
    urlParam: '/cards/:id',
  },
  getCards: {
    type: 'get',
    urlParam: '/cards',
  },
  buyCredits: {
    type: 'post',
    urlParam: '/sms_credits',
  },
  getcoupons: {
    type: 'get',
    urlParam: '/partners/coupons',
  },
}

/**
 * Instance of Users Service.
 *
 * @class
 * @usage `import UsersService from 'network/UsersService'`
 */
export default class UsersService extends Service {
  constructor(props) {
    super(props)
    this.services = SERVICES
  }

  serviceBase() {
    return 'users'
  }
}
