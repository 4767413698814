import { isEmpty } from 'lodash'

const present = (stat = {}) => {
  if (isEmpty(stat)) { return {} }

  return {
    pending: stat?.pending,
    shipped: stat?.shipped,
    completed: stat?.completed,
    declined: stat?.declined,
  }
}

export default present
