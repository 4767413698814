import Store, { setObservables } from './Store'
import { action, observable, computed, toJS } from 'mobx'
import { isEmpty, isNil } from 'lodash'
import { CookieHelper } from '@sowlutions-tech/next/common/helpers'
import Authentication from 'network/Authentication'

const OBSERVABLES = {
  _authToken: { type: observable, defaultValue: '' },
  _currentUser: { type: observable, defaultValue: '' },
  _userRoleId: { type: observable, defaultValue: '' },
  setCurrentUser: { type: action },
  logout: { type: action },
  isLoggedIn: { type: computed },
  hasUserInfo: { type: computed },
}

class AuthStore extends Store {
  constructor(initialData) {
    super(initialData)
    setObservables(this, initialData, OBSERVABLES)

    if (initialData) {
      let dataSource = initialData

      if (initialData.cookie) {
        dataSource = initialData.cookie
      }

      this._authToken = dataSource._authToken
      this._currentUser = dataSource._currentUser
      this._userRoleId = dataSource._userRoleId
    }

    if (this._authToken) {
      this.reloadUser()
    }
  }

  service() {
    return new Authentication({ authStore: this })
  }

  setCurrentUser(user) {
    const { authToken, ...userInfo } = user

    this._currentUser = userInfo
    this._authToken = authToken
    const foundCreatorRole = userInfo.roles.find(role =>
      role.role.toLowerCase() === 'creator') || {}
    this._userRoleId = foundCreatorRole.id

    CookieHelper.saveTokenData({
      tokenData: {
        _authToken: authToken,
        _currentUser: { id: userInfo.id },
        _userRoleId: foundCreatorRole.id,
      },
    })
  }

  logout() {
    CookieHelper.clearTokenData()
    this._currentUser = null
    this._authToken = null
    this._userRoleId = null
  }

  async reloadUser() {
    if (this._authToken) {
      const resp = await this.api().getUserData()
      if (resp.success) {
        const user = resp.data
        this.setCurrentUser(user)
      }
    }
  }

  get isLoggedIn() {
    return !isEmpty(this._authToken)
  }

  get authToken() {
    return toJS(this._authToken)
  }

  set authToken(authToken) {
    this._authToken = authToken
  }

  get hasUserInfo() {
    return !isEmpty(this.currentUser) && !isNil(this.currentUser.id) // ? `id` can be replaced with any key should only exist when the user info has been queried
  }

  get currentUser() {
    return toJS(this._currentUser)
  }

  get userRoleId() {
    return toJS(this._userRoleId)
  }

  get premiumCreator() {
    return this.currentUser?.premiumCreator
  }
}

export default AuthStore
