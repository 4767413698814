import { css } from '@emotion/react'
import colors from '~/styles/colors'

export const icon = size => css`
  ${size && `
      height: ${size};
      width: ${size};
  `};
`

export const image = size => css`
  ${icon(size)};
`

export const lettersDiv = size => css`
  width: 4rem;
  height: 4rem;

  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${colors.pink};
  border-radius: 100%;
`

export const lettersText = css`
  color: white;
  font-size: 1.5rem;
`
