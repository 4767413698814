import Store, { setObservables } from './Store'
import AnalyticsService from 'network/AnalyticsService.js'
import { observable, action, computed, toJS } from 'mobx'

const OBSERVABLES = {
  _dashboardAnalytics: { type: observable, defaultValue: [] },
}

class AnalyticsStore extends Store {
  constructor(initialData) {
    super(initialData)
    setObservables(this, initialData, OBSERVABLES)
  }

  service() {
    return new AnalyticsService(this.rootStore)
  }

  async getDashboardAnalytics(params) {
    return await this.accessStoreCache({
      api: 'getDashboardAnalytics',
      params,
      observable: 'dashboardAnalytics',
      setter: 'setDashboardAnalytics',
    })
  }

  setDashboardAnalytics(value) {
    this._dashboardAnalytics = value
  }

  get dashboardAnalytics() {
    return toJS(this._dashboardAnalytics)
  }
}

export default AnalyticsStore
