import Service from './Service'
import presentFeed from 'presenters/feedPresenter'
import presentApiPagination from 'presenters/apiPaginationPresenter'

export const SERVICES = {
  getAllActivities: {
    type: 'get',
    urlParam: '/by_activities',
    present: resp => {
      return {
        success: resp?.success,
        data: (resp?.data?.items || []).map(presentFeed),
        ...presentApiPagination(resp),
      }
    },
  },
}

/**
 * Instance of UserPosts Service.
 *
 * @class
 * @usage `import FeedService from 'network/FeedService'`
 */
export default class FeedService extends Service {
  constructor(props) {
    super(props)
    this.services = SERVICES
  }

  serviceBase() {
    return 'feed'
  }
}
