import { css } from '@emotion/react'
import colors from 'styles/colors'
import { transition } from 'styles/constants'
import { darken, grayscale, lighten, transparentize } from 'polished'

const buttonOutlined = ({ isDisabled, chosenColor }) => css`
  background: none;
  color: ${colors.black};

  > #button-icon {
    color: ${chosenColor};
  }

  ${isDisabled && `
    border-color: ${grayscale(chosenColor)};
    color: ${lighten(0.75, colors.black)};

    > #button-icon {
      color: ${grayscale(chosenColor)};
    }
  `};
`

const buttonFilled = ({ isDisabled, chosenColor }) => css`
  background: ${chosenColor};
  color: ${colors.white};

  ${isDisabled && `
    background: ${grayscale(chosenColor)};
    border-color: ${grayscale(chosenColor)};
    color: ${darken(0.25, colors.white)};
  `};

  > #button-icon {
    color: ${colors.white};
  }
`

const buttonInverted = ({ isDisabled, chosenColor }) => css`
  border-color: ${colors.white};
  background: ${colors.white};
  color: ${chosenColor};
  font-weight: bold;

  ${isDisabled && `
    background: ${darken(0.25, colors.white)};
    border-color: ${darken(0.25, colors.white)};
    color: ${grayscale(chosenColor)};
  `};
`

const buttonLite = ({ isDisabled, chosenColor }) => {
  const backgroundColor = transparentize(0.9, chosenColor)

  return css`
    background: ${backgroundColor};
    color: ${chosenColor};
    border-color: ${colors.transparent};
    
    > #button-icon {
      color: ${chosenColor};
    }

    ${isDisabled && `
      background: ${grayscale(backgroundColor)};
      border-color: ${colors.transparent};
      color: ${grayscale(chosenColor)};

      > #button-icon {
        color: ${grayscale(chosenColor)};
      }
    `};
  `
}

const iconButton = css`
  padding: 0.6rem;

  > #button-icon {
    margin: 0;
  }
`

export const button = ({ color, type, isDisabled, isLoading, isIconButton, borderRadius, justifyContent }) => {
  const isOutlined = type === 'outlined'
  const isFilled = type === 'filled'
  const isInverted = type === 'inverted'
  const isLite = type === 'lite'
  const chosenColor = colors[color]

  return css`
    display: inline-flex;
    align-items: center;
    justify-content: ${justifyContent};
    outline: none;
    border: 0.15rem solid ${chosenColor};
    border-radius: ${borderRadius};
    padding: 0.4rem 1.2rem;
    cursor: ${isDisabled || isLoading ? 'not-allowed' : 'pointer'};
    ${transition};

    ${isOutlined && buttonOutlined({ isDisabled, chosenColor })};
    ${isFilled && buttonFilled({ isDisabled, chosenColor })};
    ${isInverted && buttonInverted({ isDisabled, chosenColor })}
    ${isLite && buttonLite({ isDisabled, chosenColor })}
    ${isIconButton && iconButton};

    &:hover {
      ${!isDisabled && !isLoading && `
        box-shadow: 0rem 0rem 0.3rem 0.1rem ${chosenColor};
      `};
    }

    &:active {
      box-shadow: 0rem 0rem 0.3rem 0.1rem ${chosenColor};
      transition: 0.2s;
    }
  `
}

export const icon = css`
  margin-right: 0.7rem;
`

export const link = ({ color }) => css`
  text-decoration: underline;
  color: ${colors[color]} !important;
  cursor: pointer;
  ${transition};

  &:hover {
    filter: drop-shadow(0rem 0rem 0.5rem ${colors[color]});
  }
`

export const premium = css`
  font-size: 10px;
  background: ${colors.blue};
  color: white;
  padding: 2px 10px;
  margin-right: 10px;
  border-radius: 25px;
`
