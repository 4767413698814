import { Store, setObservables } from '@sowlutions-tech/next/common/stores'
import { isEmpty } from 'lodash'

export default class MobxStore extends Store {
  constructor(props) {
    super(props)
  }

  // TODO: Maybe find a better name?
  async accessStoreCache({
    api = '',
    params = {},
    observable = '',
    setter = '',
  }) {
    const hydrate = async (params) => {
      const hydrationCall = await this.api()[api](params)
      const { success, ...apiResp } = hydrationCall

      // TODO: We are only caching the first page of a list from the API.
      // Should we cache all pages? If so, how would we handle the hydration & return?
      const isNotOnFirstPage = apiResp.offset && apiResp.offset !== 0

      if (success && setter && !isNotOnFirstPage) {
        this[setter](apiResp)
      }

      return apiResp
    }

    if (isEmpty(this[observable])) {
      return await hydrate(params)
    }

    hydrate(params)
    return this[observable]
  }
}

export { setObservables }
