import React, { useState } from 'react'
import PropTypes from 'prop-types'

import Button from 'components/Button'
import Modal from 'components/Modal'
import Input from 'components/Input'

import * as style from './styles'

/**
 * This component is used to prompt user if they are sure about their choice
 *
 * @component
 * @usage import PromptModal from 'components/PromptModal'
 * @example
 * <PromptModal
 *    isOpen={isOpen}
 *    setIsOpen={setIsOpen}
 *    ....
 *    text='....'
 *    buttonText='Delete'
 *    handleClick={handleClick}
 * />
 */
const PromptModal = ({
  isOpen,
  setIsOpen,
  isLoading,
  handleClick,
  withInput,
  text,
  buttonText,
  comment,
  children,
}) => {
  const [value, setValue] = useState('')

  return (
    <Modal
      isOpen={isOpen}
      onClose={() => setIsOpen(false)}
      backgroundColor='sidebarLight'
      css={style.modal}
    >
      <p css={style.text}>{text}</p>
      <p css={style.comment}>{comment}</p>
      {withInput && (
        <Input
          className='input-container'
          style={{ color: 'white' }}
          defaultValue={value}
          isRequired
          placeholder={text}
          onChange={newValue => setValue(newValue)}
        />
      )}
      {children}
      <div css={style.modalFooter}>
        <Button color='blue' type='lite' onClick={() => setIsOpen(false)}>
          Cancel
        </Button>
        <Button color='blue' isLoading={isLoading} onClick={() => {
          if (withInput && !value) { return }
          handleClick(value)
        }}>
          {buttonText}
        </Button>
      </div>
    </Modal>
  )
}

PromptModal.propTypes = {
  isOpen: PropTypes.bool,
  setIsOpen: PropTypes.func,
  isLoading: PropTypes.bool,
  handleClick: PropTypes.func,
  text: PropTypes.string,
  comment: PropTypes.string,
  buttonText: PropTypes.string,
  withInput: PropTypes.bool,
}

PromptModal.defaultProps = {
  isOpen: false,
  setIsOpen: () => { },
  isLoading: false,
  handleClick: () => { },
  text: '',
  comment: '',
  buttonText: 'Yes',
  withInput: false,
}

export default PromptModal
