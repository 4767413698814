import presentUser from 'presenters/userPresenter'
import presentStats from 'presenters/statsPresenter'
import { isEmpty } from 'lodash'

const present = (reward = {}) => {
  if (isEmpty(reward)) { return {} }

  return {
    id: reward?.id,
    name: reward?.name,
    image: reward?.image?.large,
    description: reward?.description,
    expires: reward?.expires_at,
    type: reward?.reward_type,
    user: presentUser(reward?.user),
    stats: presentStats(reward?.stats),
    quantity: reward?.max_quantity,
    quantityUsed: reward?.used_quantity,
    remainingQuantity: reward?.remaining_quantity,
    instructions: reward?.instructions,
    price: parseInt(reward?.price),
    isPublished: reward?.published,
    templateId: reward?.reward_template?.id,
    paymentMethod: reward.payment_method,
  }
}

export default present
