import presentUser from 'presenters/userPresenter'
import { isEmpty } from 'lodash'

const present = (campaign = {}) => {
  if (isEmpty(campaign)) { return {} }

  return {
    id: campaign?.id,
    title: campaign?.campaign?.title,
    content: campaign?.campaign?.content,
    image: campaign?.campaign?.attachments?.[0]?.large,
    campaignId: campaign?.campaign_id,
    campaignCode: campaign?.code,
    user: presentUser(campaign?.campaign?.user),
    redirectLink: campaign?.campaign?.link,
    shareableLink: campaign?.shareable_link,
  }
}

export default present
