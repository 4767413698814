import Store, { setObservables } from './Store'
import UsersService from 'network/UsersService.js'
import { observable, action, computed, toJS } from 'mobx'

const OBSERVABLES = {
  _topFans: { type: observable, defaultValue: [] },
}

class UsersStore extends Store {
  constructor(initialData) {
    super(initialData)
    setObservables(this, initialData, OBSERVABLES)
  }

  service() {
    return new UsersService(this.rootStore)
  }

  async getTopFans(params) {
    return await this.accessStoreCache({
      api: 'getTopFans',
      params,
      observable: 'topFans',
      setter: 'setTopFans',
    })
  }

  setTopFans(value) {
    this._topFans = value
  }

  get topFans() {
    return toJS(this._topFans)
  }
}

export default UsersStore
