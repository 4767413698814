import React from 'react'
import PropTypes from 'prop-types'

import * as style from './styles'

/**
 * <Describe the Chip component here>
 *
 * @component
 * @usage import Chip from 'components/Chip'
 * @example
 * <Add an example of how Chip is used here>
 */
const Chip = ({ children, color, isActive, isInactive, isClickable, onClick, ...extra }) => {
  return (
    <div
      css={style.chip({ isActive, isInactive, color, isClickable })}
      onClick={isClickable ? onClick : () => {}}
      {...extra}
    >
      {children}
    </div>
  )
}

Chip.propTypes = {
  color: PropTypes.string,
  isActive: PropTypes.bool,
  isInactive: PropTypes.bool,
  isClickable: PropTypes.bool,
  onClick: PropTypes.func,
}

Chip.defaultProps = {
  color: 'pink',
  isActive: false,
  isInactive: false,
  isClickable: false,
  onClick: () => {},
}

export default Chip
