import { css } from '@emotion/react'
import colors from 'styles/colors'
import { transition } from 'styles/constants'

export const chip = ({ isActive, isInactive, color, isClickable }) => {
  const chosenColor = colors[color]

  return css`
    display: inline-flex;
    border: 0.1rem solid ${chosenColor};
    border-radius: 3rem;
    color: ${chosenColor};
    padding: 0.3rem 1.25rem;
    ${transition};
    
    ${isActive && `
      background-color: ${chosenColor};
      color: ${colors.white};
    `};

    ${isInactive && `
      border-color: ${colors.gray};
      color: ${colors.gray};
    `}

    &:hover {
      ${isClickable && `
        cursor: pointer;
        box-shadow: 0rem 0rem 1rem 0.2rem ${chosenColor};
      `}
    }
  `
}
