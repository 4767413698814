import Store, { setObservables } from './Store'
import UserSegmentsService from '~/network/UserSegmentsService.js'
// import { observable, toJS } from 'mobx'

const OBSERVABLES = {
}

class UserSegmentsStore extends Store {
  constructor(initialData) {
    super(initialData)
    setObservables(this, initialData, OBSERVABLES)
  }

  service() {
    return new UserSegmentsService(this.rootStore)
  }
}

export default UserSegmentsStore
