import Store, { setObservables } from './Store'
import CampaignsService from 'network/CampaignsService.js'
import { observable, action, computed, toJS } from 'mobx'

const OBSERVABLES = {
  _allCampaigns: { type: observable, defaultValue: [] },
}

class CampaignsStore extends Store {
  constructor(initialData) {
    super(initialData)
    setObservables(this, initialData, OBSERVABLES)
  }

  service() {
    return new CampaignsService(this.rootStore)
  }

  async getAllCampaigns(params) {
    return await this.accessStoreCache({
      api: 'getAllCampaigns',
      params,
      observable: 'allCampaigns',
      setter: 'setAllCampaigns',
    })
  }

  setAllCampaigns(value) {
    this._allCampaigns = value
  }

  get allCampaigns() {
    return toJS(this._allCampaigns)
  }
}

export default CampaignsStore
