import React from 'react'
import PropTypes from 'prop-types'

import Icon from 'components/Icon'

import * as style from './styles'

/**
 * <Describe the Spinner component here>
 *
 * @component
 * @usage import Spinner from 'components/Spinner'
 * @example
 * <Add an example of how Spinner is used here>
 */
const Spinner = (props) => {
  return (
    <Icon icon='spinner' css={style.spinner} {...props} />
  )
}

Spinner.propTypes = {
}

Spinner.defaultProps = {
}

export default Spinner
