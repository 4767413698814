import { css } from '@emotion/react'
import colors from 'styles/colors'

export const modal = css`
	color: ${colors.white};
	text-align: center;
	display: flex;
	flex-direction: column;
	align-items: center;

	.input-container {
		width: 70%;
		color: colors.white;
	}
`

export const modalFooter = css`
	margin-top: 1rem;
	display: flex;
	justify-content: space-between;
	width: 70%;
`

export const text = css`
	margin-bottom: 0;
`

export const comment = css`
  margin: 0.5rem 0;
  font-size: 0.7rem;
`
