import React, { useState, useMemo, useEffect } from 'react'
import PropTypes from 'prop-types'
import { router, Link } from 'lib/router'

import Icon from 'components/Icon'
import Tooltip from 'components/Tooltip'
import Button from 'components/Button'

import * as style from './styles'
import ProgressBar from '../ProgressBar'

/**
 * Sidebar
 *
 * @component
 * @usage `import Sidebar from 'components/Sidebar'`
 */

const Sidebar = ({ currentRoute, setRouteSelected, setIsOpen, isDataWritten, user }) => {
  const [isExpanded, setIsExpanded] = useState(false)
  const { routes } = router

  const menus = useMemo(() => {
    return [
      { id: 'home', ...routes.home, label: 'Dashboard', icon: 'dashboard' },
      {
        id: 'campaigns',
        ...routes.campaigns,
        group: 'campaign',
        label: 'Links',
        icon: 'campaignLink',
      },
      { id: 'rewards', ...routes.rewards, group: 'reward', label: 'Shop', icon: 'shop' },
      { id: 'feed', ...routes.feed, label: 'Feed', icon: 'house' },
      { id: 'fansListing', ...routes.fansListing, icon: 'fans', label: 'Your Crowd' },
      {
        id: 'userMassMessages',
        ...routes.userMassMessages,
        icon: 'massMessage',
        label: 'Message Fans',
        group: 'mass_messaging',
      },
      {
        id: 'segmentsListing',
        ...routes.segmentsListing,
        icon: 'messageGroup',
        label: 'Fan Groups',
        group: 'mass_messaging',

      },
      {
        id: 'moneyTab',
        icon: 'payment',
        label: 'Money',
        childrenMenus: [
          { id: 'paymentDetails', ...routes.paymentDetails, label: 'Billing', icon: 'payment' },
          { id: 'earnings', ...routes.earnings, label: 'Earnings', icon: 'payment' },
        ],
      },

      { id: 'customize', ...routes.customize, label: 'Customize', icon: 'edit' },
      { id: 'logout', ...routes.logout, label: 'Logout', icon: 'logout' },
      { id: 'progress', ...routes.updateInfo, label: 'progress' },
    ]
  }, [])

  const handleClick = (routeKey) => {
    setRouteSelected(routeKey)
    isDataWritten ? setIsOpen(true) : setIsOpen(false)
  }

  const ChildComponent = ({ isActive, isChild, icon, rightIcon, label }) => (
    <div css={style.route({ isChild, isActive })}>
      {icon && <Icon icon={icon} withGradient={isActive} css={style.routeIcon} />}
      <span css={style.routeLabel({ isExpanded })}>{label}</span>

      {rightIcon && (
        <div css={style.rightIconDiv}>
          <Icon icon={rightIcon} withGradient={isActive} css={style.rightIcon} />
        </div>
      )}
    </div>
  )

  const isActive = (basePath) =>
    basePath.includes(currentRoute?.extra?.group) || currentRoute.basePath === basePath

  const RouteObject = ({ routeKey, isChild, basePath, path, icon, rightIcon, label, id }) => {
    const routeActive = isActive(basePath)

    const ComponentToShow = () => (
      <ChildComponent
        isChild={isChild}
        isActive={routeActive}
        icon={icon}
        rightIcon={rightIcon}
        label={label}
      />
    )

    return (
      <li id={`mainMenu_${id}`} key={`route-${routeKey}`} onClick={() => handleClick(routeKey)}>
        <Tooltip content={label} trigger='hover' animation='grow'>
          {isDataWritten && <ComponentToShow />}
          {id !== 'progress' && !isDataWritten && (
            <Link href={path} css={style.routeContainer}>
              <ComponentToShow />
            </Link>
          )}
          {id === 'progress' && user && (
            <Link href={path} css={style.routeContainer}>
              <ProgressBar user={user} />
            </Link>
          )}
        </Tooltip>
      </li>
    )
  }

  const MenuGroup = ({ icon, label, id, childrenMenus }) => {
    const [menuOpen, setMenuOpen] = useState(false)
    const [menuActive, setMenuActive] = useState(false)
    useEffect(() => {
      childrenMenus.forEach((menu) => {
        if (isActive(menu.basePath)) {
          setMenuOpen(true)
          setMenuActive(true)
          return
        }
      })
    }, [])

    return (
      <li id={`mainMenu_${id}`}>
        <div onClick={() => setMenuOpen(!menuOpen)}>
          <ChildComponent
            icon={icon}
            label={label}
            rightIcon={menuOpen ? 'arrowDown' : 'arrowUp'}
            isActive={menuActive}
          />
        </div>

        {menuOpen
          && childrenMenus.map((menu) => <RouteObject key={menu.routeKey} {...menu} isChild={true} />)}
      </li>
    )
  }

  return (
    <>
      <div css={style.sidebarSpacer} />
      <aside css={style.sidebar({ isExpanded })}>
        <ul css={style.routesContainer}>
          {menus.map((route) => {
            if (route.childrenMenus) {
              return <MenuGroup key={route.id} {...route} />
            } else {
              return <RouteObject key={route.id} {...route} />
            }
          })}
        </ul>

        <Button
          css={style.buttonExpand}
          icon={isExpanded ? 'arrowLeftDouble' : 'arrowRightDouble'}
          type='filled'
          onClick={() => setIsExpanded(!isExpanded)}
        />
      </aside>
    </>
  )
}

Sidebar.propTypes = {
  currentRoute: PropTypes.object,
}

Sidebar.defaultProps = {
  currentRoute: {},
}

export default Sidebar
