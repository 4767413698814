import { css } from '@emotion/react'
import colors from 'styles/colors'
import { transition, borderRadius, inputPadding } from 'styles/constants'
import { transparentize } from 'polished'

const PLACEHOLDER_COLOR = transparentize(0.5, colors.gray)

export const select = isValueSelected => css`
  width: 100%;
  outline: none;
  padding: ${inputPadding};
  border: 0.1rem solid ${PLACEHOLDER_COLOR};
  border-radius: ${borderRadius};
  ${transition};

  ${!isValueSelected && `
    color: ${PLACEHOLDER_COLOR};
  `}

  &:focus {
    border-color: ${colors.orange};
  }
`

export const option = css`
  color: initial;
  padding: ${inputPadding};
`
