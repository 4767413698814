import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'

import Icon from 'components/Icon'
import { REWARD_TYPES } from '~/constants'

import * as style from './styles'

/**
 * <Describe the Image component here>
 *
 * @component
 * @usage import Image from 'components/Image'
 * @example
 * <Add an example of how Image is used here>
 */
const Image = ({ src, image, size, type, user, ...extra }) => {
  const imageSrc = src || image
  const [userInitials, setUserInitials] = useState('')

  useEffect(() => {
    setUserInitials('')

    if (user?.name) {
      setUserInitials(
        (user.name?.trim() || '').split(' ').reduce((acc, subname) => acc + subname[0], '')
      )
    }
  }, [user])

  if (!imageSrc && type) {
    return <Icon icon={REWARD_TYPES[type].icon} withGradient css={style.icon(size)} {...extra} />
  }

  if ((!imageSrc || imageSrc.includes('avatar-icon.png')) && userInitials) {
    return (
      <div css={style.lettersDiv(size)}>
        <span css={style.lettersText}>{userInitials}</span>
      </div>
    )
  }

  return <img src={imageSrc} css={style.image(size)} {...extra} />
}

Image.propTypes = {
  src: PropTypes.string,
  image: PropTypes.string,
  size: PropTypes.string,
  type: PropTypes.string,
  letters: PropTypes.string,
}

Image.defaultProps = {
  src: '',
  image: '',
  size: '5rem',
  type: '',
  letters: null,
}

export default Image
