import React from 'react'
import PropTypes from 'prop-types'

import Title from 'components/Title'
import Icon from 'components/Icon'
import { motion, AnimatePresence } from 'framer-motion'

import * as style from './styles'

const ANIMATION_DURATION = 0.75

/**
 * <Describe the Modal component here>
 *
 * @component
 * @usage import Modal from 'components/Modal'
 * @example
 * <Add an example of how Modal is used here>
 */
const Modal = ({ children, title, backgroundColor, isOpen, onClose, ...extra }) => {
  return (
    <AnimatePresence>
      {
        !isOpen
          ? null
          : (
            <motion.aside
              css={style.backdrop}
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              transition={{ type: 'tween', duration: ANIMATION_DURATION }}
              onClick={onClose}
            >
              <motion.div
                css={style.modal({ backgroundColor })}
                initial={{ top: '100%' }}
                animate={{ top: '0' }}
                exit={{ top: '-100%' }}
                transition={{ type: 'spring', bounce: 0.25, duration: ANIMATION_DURATION }}
                onClick={ev => ev.stopPropagation()}
              >
                <Icon
                  icon='close'
                  css={style.iconClose}
                  onClick={onClose}
                />
                {title && <Title>{title}</Title>}
                <div css={style.modalContent} {...extra}>
                  {children}
                </div>
                <div />
              </motion.div>
            </motion.aside>
          )
      }
    </AnimatePresence>
  )
}

Modal.propTypes = {
  title: PropTypes.string,
  backgroundColor: PropTypes.string,
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
}

Modal.defaultProps = {
  title: '',
  backgroundColor: '',
  isOpen: false,
  onClose: () => {},
}

export default Modal
