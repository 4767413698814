import { css } from '@emotion/react'
import colors from 'styles/colors'

export const badgeWrapper = css`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
`

export const badgeDot = ({ color, hasContent }) => css`
  position: absolute;
  top: 0;
  right: 0;
  width: 0.5rem;
  height: 0.5rem;
  border-radius: 100%;
  background-color: ${colors[color]};

  ${hasContent && `
    display: flex;
    align-items: center;
    justify-content: center;
    width: 0.75rem;
    height: 0.75rem;
    font-size: 0.4rem;
  `};
`

export const notificationIcon = css`
  font-size: 1.25rem;
  margin-left: 1rem;
`
