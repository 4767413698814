const DEFAULT_PALLETTE = {
  transparent: '#ffffff00',
  black: '#000',
  gray: '#757883',
  grayLight: '#F4F5F8',
  red: '#E7302A',
  yellow: '#F7EB83',
  white: '#FFF',
  green: '#65e0b3',
  orange: '#F29E5F',
  purple: '#8e2e8c',
  blue: '#379EA9',
  pink: '#E96B83',
}

const CUSTOM_COLORS = {
  sidebarDark: '#11131F',
  sidebarLight: '#292C38',
  campaignBackground: '#E98E63',
  campaignButton: '#EA8B67',
  headerGray: '#DEE0E3',
  background: '#F9F9FC',
  facebook: '#1877F2',
  twitter: '#1c9cea',
}

export default {
  ...DEFAULT_PALLETTE,
  ...CUSTOM_COLORS,
}
