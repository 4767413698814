import { css } from '@emotion/react'
import colors from 'styles/colors'

export const clickable = css`
  color: ${colors.gray} !important;
  text-decoration: none;
`

export const breadcrumbs = css`
  color: ${colors.gray};
  font-size: 0.75rem;
  cursor: default;
`

export const pathMiddle = css`
  text-decoration: none;
`

export const pathTail = css`
  color: ${colors.pink};
`
