import { css } from '@emotion/react'
import colors from 'styles/colors'

export const icon = ({ color, withGradient, textColor }) => css`
  ${!withGradient
  && `
    [data-name="main-path"] {
      fill: ${colors[color] || 'currentColor'};
    }

    [data-name="main-path-stroke"] {
      stroke: ${colors[color] || 'currentColor'};
    }
  `}

  ${textColor
  && !withGradient
  && `
    [data-name="main-path"] {
      fill: #${textColor} !important;
    }

    [data-name="main-path-stroke"] {
      stroke: #${textColor} !important;
    }
  `}

  ${withGradient
  && `
    linearGradient {
      stop[data-name='gradient-1'] {
        stop-color: ${colors.pink};
      }

      stop[data-name='gradient-2'] {
        stop-color: ${colors.orange};
      }
    }
  `};

  ${textColor
  && withGradient
  && `
    linearGradient {
      stop[data-name='gradient-1'] {
        stop-color: ${textColor};
      }

      stop[data-name='gradient-2'] {
        stop-color: ${textColor};
      }
    }
  `};
`
