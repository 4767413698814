import { isEmpty } from 'lodash'

const present = (rewardTemplate = {}) => {
  if (isEmpty(rewardTemplate)) { return {} }

  return {
    id: rewardTemplate?.id,
    label: rewardTemplate?.name,
    type: rewardTemplate?.reward_type,
    image: rewardTemplate?.image?.thumbnail,
    order: rewardTemplate?.sort_order,
    titlePlaceholder: rewardTemplate?.title_placeholder,
    descriptionPlaceholder: rewardTemplate?.description_placeholder,
    instructionsPlaceholder: rewardTemplate?.instructions_placeholder,
  }
}

export default present
