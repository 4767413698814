import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'

import Image from 'components/Image'

import { useDropzone } from 'react-dropzone'
import Button from 'components/Button'

import * as style from './styles'

/**
 * <Describe the ImageAvatar component here>
 *
 * @component
 * @usage import ImageAvatar from 'components/ImageAvatar'
 * @example
 * <Add an example of how ImageAvatar is used here>
 */
const ImageAvatar = ({ isEditing, src, isRounded, user, onChange, ...extra }) => {
  const [image, setImage] = useState(src)

  useEffect(() => setImage(src), [src])

  const { getInputProps, open } = useDropzone({
    maxFiles: 1,
    accept: 'image/*',
    noClick: true,
    noKeyboard: true,
    onDrop: (acceptedFiles) => {
      const firstImage = acceptedFiles[0]
      const newImage = Object.assign(firstImage, {
        preview: URL.createObjectURL(firstImage),
      })
      setImage(newImage.preview)
      onChange(newImage)
    },
  })

  if (!isEditing) {
    return (
      <Image
        user={user}
        css={style.imageAvatar({ borderRadius: isRounded ? '50%' : null })}
        src={image}
        {...extra}
      />
    )
  }

  return (
    <div css={style.editContainer} className={extra.className}>
      <Image
        user={user}
        css={style.imageAvatar({ borderRadius: isRounded ? '100%' : null })}
        src={image}
        {...extra}
      />
      <input {...getInputProps()} />
      <Button icon='camera' type='filled' css={style.buttonEdit} onClick={open} />
    </div>
  )
}

ImageAvatar.propTypes = {
  src: PropTypes.string.isRequired,
  isEditing: PropTypes.bool,
  onChange: PropTypes.func,
  isRounded: PropTypes.bool,
  user: PropTypes.object,
}

ImageAvatar.defaultProps = {
  src: '',
  isEditing: false,
  isRounded: false,
  onChange: () => {},
  user: null,
}

export default ImageAvatar
