import { css } from '@emotion/react'
import colors from 'styles/colors'

export const tooltip = ({ color }) => css`
  background: ${colors[color]};
  color: ${colors.white};
  padding: 0.5rem 2rem;
  border-radius: 0.2rem;
  font-weight: 200;
  font-size: 0.9rem;
`
