import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import { router, Link } from 'lib/router'

import Button from 'components/Button'
import { isEmpty, isObject } from 'lodash'

import * as style from './styles'

/**
 * <Describe the Breadcrumbs component here>
 *
 * @component
 * @usage import Breadcrumbs from 'components/Breadcrumbs'
 * @example
 * <Add an example of how Breadcrumbs is used here>
 */
const Breadcrumbs = ({ crumbs, currentRoute, pageTitle }) => {
  let crumbsParsed = ['Home']
  const hasCrumbs = !isEmpty(crumbs)

  if (Boolean(!currentRoute?.extra?.label)) {
    crumbsParsed = []
  } else {
    if (hasCrumbs) {
      crumbs.forEach(crumb => {
        crumbsParsed.push(router.routes[crumb])
      })
    }

    crumbsParsed.push(currentRoute)
  }

  return (
    <div css={style.breadcrumbs}>
      {
        crumbsParsed.map((route, index) => {
          const isActualRoute = isObject(route)
          const routeKey = isActualRoute ? route.key : route
          const routeLabel = isActualRoute ? route?.extra?.label : route
          const isFirstPath = index === 0
          const isLastPath = index + 1 === crumbsParsed.length

          let pathLinked = routeLabel

          if (isLastPath) {
            pathLinked = (
              <span css={style.pathTail}>
                {pageTitle || routeLabel}
              </span>
            )
          } else if (isActualRoute) {
            pathLinked = (
              <Link href={route} css={style.pathMiddle}>
                {routeLabel}
              </Link>
            )
          }

          if (!isFirstPath) {
            return <Fragment key={`breadcrumb-${routeKey}`}> / {pathLinked} </Fragment>
          }

          return (
            <Link
              key={`breadcrumb-${routeKey}`}
              href='/admin'
              css={style.clickable}
            >
              {pathLinked}
            </Link>
          )
        })
      }
    </div>
  )
}

Breadcrumbs.propTypes = {
  crumbs: PropTypes.array.isRequired,
  pageTitle: PropTypes.string,
}

Breadcrumbs.defaultProps = {
  crumbs: [],
  pageTitle: '',
}

export default Breadcrumbs
