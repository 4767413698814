import React from 'react'
import PropTypes from 'prop-types'

import Icon from 'components/Icon'
import Select from 'components/Select'
import PhoneInput from 'react-phone-number-input'

import * as style from './styles'

/**
 * <Describe the Input component here>
 *
 * @component
 * @usage import Input from 'components/Input'
 * @example
 * <Add an example of how Input is used here>
 */
const Input = (props) => {
  const {
    icon, theme, onChange, error, type, defaultValue,
    className, name, placeholder, isRequired, keepTrack,
    help,
    ...extra
  } = props
  const withIcon = Boolean(icon)
  const hasError = Boolean(error)
  const isTextAreaInput = type.toLowerCase() === 'textarea'
  const isHtmlInput = type.toLowerCase() === 'html'
  const isNumberInput = type.toLowerCase() === 'number'
  const isDateInput = type.toLowerCase() === 'date'
  const isDateTimeInput = type.toLowerCase() === 'datetime'
  const isCheckboxInput = type.toLowerCase() === 'checkbox'
  const isSelect = type.toLowerCase() === 'select'
  const isPhoneNumber = type.toLowerCase() === 'phonenumber'

  const handleChange = (ev) => {
    keepTrack()
    if (isCheckboxInput) {
      onChange(ev.target.checked, ev)
    } else if (isSelect) {
      onChange(ev)
    } else if (isDateTimeInput) {
      const newValue = ev.target.value
      const [date, time] = newValue.split('T')
      const [year, month, day] = date.split('-')
      const [hour, minute] = time.split(':')
      onChange(
        new Date(year, month, day, hour, minute, '00')
      )
    } else if (isPhoneNumber) {
      onChange(ev)
    } else {
      onChange(ev.target.value, ev)
    }
  }
  const onChangeHandle = (ev) => handleChange(ev)

  const inputProps = {
    css: [style.input({ withIcon, hasError, theme })],
    onChange: onChangeHandle,
    placeholder: isRequired ? `* ${placeholder}` : placeholder,
    ...extra,
  }

  const getValidNumber = (ev) => {
    const length = ev.target.value.length
    const charCode = ev.target.value.charCodeAt(length - 1)
    if (charCode < 48 || charCode > 57) {
      ev.target.value = ev.target.value.slice(0, -1)
    }
  }

  if (isSelect) {
    return (
      <div css={style.selectDiv}>
        <Select
          {...props}
          {...inputProps}
          defaultValue={defaultValue}
        />
      </div>
    )
  }

  let Component = <input {...inputProps} type={type} value={defaultValue} />

  if (isTextAreaInput || isHtmlInput) {
    inputProps.css.push(style.textArea)
    Component = <textarea {...inputProps} defaultValue={defaultValue} />
  } else if (isNumberInput) {
    Component = (
      <input
        {...inputProps}
        defaultValue={defaultValue}
        onChange={(ev) => {
          getValidNumber(ev)
          onChangeHandle(ev)
        }}
      />
    )
  } else if (isDateInput) {
    Component = (
      <input
        {...inputProps}
        type='date'
        defaultValue={defaultValue}
      />
    )
  } else if (isDateTimeInput) {
    Component = (
      <input
        {...inputProps}
        type='datetime-local'
        defaultValue={defaultValue}
      />
    )
  } else if (isCheckboxInput) {
    Component = (
      <div>
        <input {...inputProps} defaultChecked={defaultValue} type='checkbox' id={name} />
        <label htmlFor={name}>{placeholder}</label>
      </div>
    )
  } else if (isPhoneNumber) {
    Component = (
      <PhoneInput
        defaultCountry='US'
        {...inputProps}
      />
    )
  }

  return (
    <>
      <div className={className} css={style.inputContainer({ hasError })}>
        <div className='input-div'>
          {Component}
          {withIcon && <Icon id='input-icon' icon={icon} css={style.icon} />}
          {hasError && <small css={style.error}>{error}</small>}
        </div>
        {help && <small css={style.help}>{help}</small>}
      </div>
    </>
  )
}

Input.propTypes = {
  icon: PropTypes.string,
  theme: PropTypes.oneOf(['', 'dark', 'light']),
  onChange: PropTypes.func,
  error: PropTypes.string,
  type: PropTypes.string,
  defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool]),
  className: PropTypes.string, // ? Autmatocally caught from the passed `css` props
  name: PropTypes.string,
  placeholder: PropTypes.string,
  isRequired: PropTypes.bool,
  keepTrack: PropTypes.func,
}

Input.defaultProps = {
  icon: '',
  theme: '',
  onChange: () => { },
  error: '',
  type: '',
  defaultValue: '',
  className: '',
  name: '',
  placeholder: '',
  isRequired: false,
  keepTrack: () => { },
}

export default Input
