import logo from 'public/static/logo.svg'
import logoWithGlyph from 'public/static/logo-with-glyph.svg'

import facebook from '~/public/static/icons/socials/facebook.svg'
import soundcloud from '~/public/static/icons/socials/soundcloud.svg'
import spotify from '~/public/static/icons/socials/spotify.svg'
import twitter from '~/public/static/icons/socials/twitter.svg'
import youtube from '~/public/static/icons/socials/youtube.svg'
import instagram from '~/public/static/icons/socials/instagram.svg'

import apple from 'public/static/icons/stores/apple-store.svg'
import google from 'public/static/icons/stores/google-store.svg'

import activate from '~/public/static/icons/activate.svg'
import arrowDown from '~/public/static/icons/arrow-down.svg'
import arrowLeft from '~/public/static/icons/arrow-left.svg'
import arrowLeftDouble from '~/public/static/icons/arrow-left-double.svg'
import arrowRight from '~/public/static/icons/arrow-right.svg'
import arrowRightDouble from '~/public/static/icons/arrow-right-double.svg'
import arrowUp from '~/public/static/icons/arrow-up.svg'
import bell from '~/public/static/icons/bell.svg'
import camera from '~/public/static/icons/camera.svg'
import campaignLink from '~/public/static/icons/campaignLink.svg'
import campaignRedeem from '~/public/static/icons/campaignRedeem.svg'
import campaignShare from '~/public/static/icons/campaignShare.svg'
import chatBubble from '~/public/static/icons/chat-bubble.svg'
import chevronLeft from '~/public/static/icons/chevron-left.svg'
import chevronRight from '~/public/static/icons/chevron-right.svg'
import checkCircle from '~/public/static/icons/check-circle.svg'
import click from '~/public/static/icons/click.svg'
import clock from '~/public/static/icons/clock.svg'
import close from '~/public/static/icons/close.svg'
import coins from '~/public/static/icons/coins.svg'
import copy from '~/public/static/icons/copy.svg'
import coupon from '~/public/static/icons/coupon.svg'
import customize from '~/public/static/icons/customize.svg'
import dashboard from '~/public/static/icons/dashboard.svg'
import deactivate from '~/public/static/icons/deactivate.svg'
import deleteIcon from '~/public/static/icons/delete.svg'
import delivery from '~/public/static/icons/delivery.svg'
import distributed from '~/public/static/icons/distributed.svg'
import download from '~/public/static/icons/download.svg'
import dragAndDrop from '~/public/static/icons/drag-and-drop.svg'
import edit from '~/public/static/icons/edit.svg'
import filter from '~/public/static/icons/filter.svg'
import gift from '~/public/static/icons/gift.svg'
import giftFilled from '~/public/static/icons/gift-filled.svg'
import house from '~/public/static/icons/house.svg'
import increase from '~/public/static/icons/increase.svg'
import like from '~/public/static/icons/like.svg'
import logout from '~/public/static/icons/logout.svg'
import megaphone from '~/public/static/icons/megaphone.svg'
import massMessage from '~/public/static/icons/massMessage.svg'
import newUsers from '~/public/static/icons/new-users.svg'
import ongoing from '~/public/static/icons/ongoing.svg'
import pending from '~/public/static/icons/pending.svg'
import redirect from '~/public/static/icons/redirect.svg'
import search from '~/public/static/icons/search.svg'
import share from '~/public/static/icons/share.svg'
import send from '~/public/static/icons/send.svg'
import shipped from '~/public/static/icons/shipped.svg'
import socials from '~/public/static/icons/socials.svg'
import sort from '~/public/static/icons/sort.svg'
import sortUp from '~/public/static/icons/sort-up.svg'
import sortDown from '~/public/static/icons/sort-down.svg'
import spinner from '~/public/static/icons/spinner.svg'
import toggleOff from '~/public/static/icons/toggle-off.svg'
import toggleOn from '~/public/static/icons/toggle-on.svg'
import users from '~/public/static/icons/users.svg'
import video from '~/public/static/icons/video.svg'
import plus from '~/public/static/icons/plus-icon.svg'
import info from '~/public/static/icons/info.svg'
import campaign from '~/public/static/icons/campaign.svg'
import shop from '~/public/static/icons/shop.svg'
import fans from '~/public/static/icons/fans.svg'
import fansTab from '~/public/static/icons/fansTab.svg'
import payment from '~/public/static/icons/payment.svg'

import frmSms from '~/public/static/icons/frm/sms.svg'
import frmSent from '~/public/static/icons/frm/sent.svg'
import frmEmail from '~/public/static/icons/frm/email.svg'
import frmCalendar from '~/public/static/icons/frm/calendar.svg'
import frmTick from '~/public/static/icons/frm/tick.svg'
import frmPlay from '~/public/static/icons/frm/play.svg'

import lpArrowDown from '~/public/static/icons/landing-page/arrow-down.svg'
import lpClose from '~/public/static/icons/landing-page/close.svg'
import lpTitleLine from '~/public/static/icons/landing-page/title-line.svg'
import lpShare from '~/public/static/icons/landing-page/share-icon.svg'
import lpFacebook from '~/public/static/icons/landing-page/facebook-icon.svg'
import lpTwitter from '~/public/static/icons/landing-page/twitter-icon.svg'
import lpInstagram from '~/public/static/icons/landing-page/insta-icon.svg'
import lpSoundcloud from '~/public/static/icons/landing-page/soundcloud-icon.svg'
import lpYoutube from '~/public/static/icons/landing-page/youtube-icon.svg'
import lpP from '~/public/static/icons/landing-page/p-icon.svg'

import facebookLogin from '~/public/static/icons/facebookLogin.svg'
import googleLogin from '~/public/static/icons/googleLogin.svg'
import settings from '~/public/static/icons/settings.svg'
import messageGroup from '~public/static/icons/message-group.svg'

import downwordarrowcurved from '~/public/static/icons/downward-arrow-curve.svg'
import circularArrowDown from '~public/static/icons/circle-curved-arrow-down.svg'

const ICONS = {
  // ? Logo
  crowdmouthWithGlyph: logoWithGlyph,
  crowdmouth: logo,
  // ? Socials
  facebook,
  soundcloud,
  spotify,
  twitter,
  youtube,
  instagram,
  // ? Stores
  activate,
  apple,
  google,
  // ? Others
  arrowDown,
  arrowLeft,
  arrowLeftDouble,
  arrowRight,
  arrowRightDouble,
  arrowUp,
  bell,
  camera,
  campaignLink,
  campaignRedeem,
  campaignShare,
  chatBubble,
  chevronLeft,
  chevronRight,
  checkCircle,
  click,
  clock,
  close,
  coins,
  copy,
  coupon,
  customize,
  dashboard,
  deactivate,
  delete: deleteIcon,
  delivery,
  distributed,
  download,
  dragAndDrop,
  edit,
  filter,
  gift,
  giftFilled,
  house,
  increase,
  like,
  logout,
  megaphone,
  newUsers,
  ongoing,
  pending,
  search,
  redirect,
  share,
  send,
  shipped,
  socials,
  sort,
  sortUp,
  sortDown,
  spinner,
  toggleOff,
  toggleOn,
  users,
  video,
  plus,
  info,
  frmSms,
  frmSent,
  frmEmail,
  frmCalendar,
  frmTick,
  frmPlay,
  campaign,
  shop,
  massMessage,
  fans,
  fansTab,
  payment,

  lpArrowDown,
  lpClose,
  lpTitleLine,
  lpShare,
  lpFacebook,
  lpTwitter,
  lpInstagram,
  lpSoundcloud,
  lpYoutube,
  lpP,
  facebookLogin,
  googleLogin,
  settings,
  messageGroup,
  downwordarrowcurved,
  circularArrowDown,
}

export default ICONS
