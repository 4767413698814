import Service from './Service'

export const SERVICES = {
  create: {
    type: 'post',
  },
}

/**
 * Instance of UserActions Service.
 *
 * @class
 * @usage `import UserActionsService from 'network/UserActionsService'`
 */
export default class UserActionsService extends Service {
  constructor(props) {
    super(props)
    this.services = SERVICES
  }

  serviceBase() {
    return 'user_actions'
  }
}
