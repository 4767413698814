import Store, { setObservables } from './Store'
import RewardRequestsService from 'network/RewardRequestsService.js'
import { observable, action, computed, toJS } from 'mobx'

const OBSERVABLES = {
}

class RewardRequestsStore extends Store {
  constructor(initialData) {
    super(initialData)
    setObservables(this, initialData, OBSERVABLES)
  }

  service() {
    return new RewardRequestsService(this.rootStore)
  }
}

export default RewardRequestsStore
