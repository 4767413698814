import Store, { setObservables } from './Store'
import { observable, toJS } from 'mobx'

// ? The observables will be indicated by:
// ?    1. The presence of an underscore before the var name
// ?    2. Having a default value that is not `undefined`
// ? Once an observable has been identified, it will automatically be linked
// ? to it's appropriate setter/getter (granted the functions have been declared).
// ?
// ? EXAMPLE OF THE REQUIRED FORMAT:
// ? Observable: `_myObservable`
// ? Getter: `get myObservable() => {}`
// ? Setter: `setMyObservable() => {}`

const OBSERVABLES = {
  // ? Observables are specified here.
  _observableA: { type: observable, defaultValue: '' },
  _observableB: { type: observable, defaultValue: '' },
  // ? Additional action setters can be specified here
  // setObservableA: { type: action },
  // setObservableB: { type: action },
  // ? Additional computed getters can be specified here
  // observableA: { type: computed },
  // observableB: { type: computed },
  // ? Anything else can be added, given that the `type` is a valid MobX type
}

class TestStore extends Store {
  constructor(initialData = {}) {
    super(initialData)
    setObservables(this, initialData, OBSERVABLES)
  }

  setObservableA(value) {
    this._observableA = value
  }

  setObservableB(value) {
    this._observableB = value
  }

  get observableA() {
    return toJS(this._observableA)
  }

  get observableB() {
    return toJS(this._observableB)
  }

  returnSomething() {
    return 'Something from TestStore'
  }

  changeVarD(value) {
    this.rootStore.exampleStore.setObservableTwo(value)
    this.setObservableB(value)
  }
}

export default TestStore
