import Service from './Service'
import presentPost from 'presenters/postPresenter'

export const SERVICES = {
  getPost: {
    type: 'get',
    urlParam: '/by_code/:code',
    present: resp => {
      return {
        success: resp?.success,
        data: presentPost(resp?.data || {}),
      }
    },
  },
  getAllPosts: {
    type: 'get',
    present: resp => {
      return {
        success: resp?.success,
        data: (resp?.data.items || []).map(presentPost),
      }
    },
  },
  createPost: {
    type: 'postWithFormData',
  },
  updatePost: {
    type: 'putWithFormData',
    urlParam: '/:id',
  },
  deletePost: {
    type: 'delete',
    urlParam: '/:id',
  },
}

/**
 * Instance of UserPosts Service.
 *
 * @class
 * @usage `import UserPostsService from 'network/UserPostsService'`
 */
export default class UserPostsService extends Service {
  constructor(props) {
    super(props)
    this.services = SERVICES
  }

  serviceBase() {
    return 'user_posts'
  }
}
