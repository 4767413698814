import presentInterest from 'presenters/interestPresenter'
import presentRole from 'presenters/rolePresenter'
import { isEmpty } from 'lodash'

const present = (user = {}) => {
  if (isEmpty(user)) {
    return {}
  }

  return {
    id: user?.id,
    authToken: user?.auth_token,
    bio: user?.bio,
    name: user?.name,
    subtitle: user?.subtitle,
    email: user?.email,
    username: user?.username,
    image: user?.image?.large,
    coverImage: user?.cover_photo?.large,
    interests: (user?.interests || []).map(presentInterest),
    roles: (user?.rolee_roles || []).map(presentRole),
    isCreator: user?.has_creator_role,
    socials: user?.social_media || [],
    followersCount: user?.followers_count,
    followingCount: user?.following_count,
    country: user?.country,
    city: user?.city,
    phoneNumber: user?.phone_number,
    phoneNumberCountryCode: user?.phone_number_country_code,
    address1: user?.address_line_1,
    address2: user?.address_line_2,
    zipcode: user?.zipcode,
    phone: user?.phone_number,
    shareableLink: user?.shareable_link,
    premiumCreator: user?.premium_creator,
    stripeId: user?.stripe_id,
    profileEnableCrowdmouthBranding: user?.profile_enable_crowdmouth_branding,
    profileEnableEarnRewards: user?.profile_enable_earn_rewards,
    profileCustomColor: user?.profile_custom_color,
    profileDefaultTab: user?.profile_default_tab,
    textColor: user?.text_color,
    partnerId: user?.partner_id,
    smsCredits: user?.sms_credits,
    // ...user,
  }
}

export default present
