import { css } from '@emotion/react'
import colors from 'styles/colors'
import { transparentize } from 'polished'
import { zIndexes, borderRadius } from 'styles/constants'
import { mqMax } from 'styles/mixins/mediaQueries'

const MODAL_PADDING = '1rem'

export const backdrop = css`
	position: fixed;
	display: flex;
	align-items: center;
	justify-content: center;
	background: ${transparentize(0.5, colors.black)};
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	backdrop-filter: blur(0.25rem);
	z-index: ${zIndexes.modal};
`

export const modal = ({ backgroundColor }) => css`
	position: relative;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: space-between;
	min-width: 30rem;
	background: ${colors.white};
	padding: ${MODAL_PADDING};
	border-radius: ${borderRadius};
	background-color: ${colors[backgroundColor]};

	${mqMax.sm} {
		min-width: 100%;
		min-height: 100%;
	}
`

export const title = css`
	/* position: absolute; */
	/* top: ${MODAL_PADDING}; */
	/* margin: 0; */
	text-align: center;
`

export const iconClose = css`
	position: absolute;
	top: ${MODAL_PADDING};
	right: ${MODAL_PADDING};
	cursor: pointer;
`

export const modalContent = css`
	width: 100%;
	height: 100%;
	margin: auto 0;
`
