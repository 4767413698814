import presentUser from 'presenters/userPresenter'
import presentUserCampaign from 'presenters/userCampaignPresenter'
import { formatDate } from 'helpers/dateHelper'
import { isEmpty } from 'lodash'

const present = (campaign = {}) => {
  if (isEmpty(campaign)) { return {} }

  return {
    id: campaign?.id,
    title: campaign?.title,
    content: campaign?.content,
    image: campaign?.image?.large,
    campaignCode: campaign?.code,
    userId: campaign?.user_id,
    user: presentUser(campaign?.user),
    totalShares: campaign?.total_shares,
    totalClicks: campaign?.total_clicks,
    differencePercentage: campaign?.stats?.difference_percentage,
    link: campaign?.link,
    shareableLink: campaign?.shareable_link,
    featured: campaign?.featured,
    status: campaign?.status,
    starts: campaign?.starts_at ? formatDate(campaign?.starts_at, 'YYYY-MM-dd') : null,
    ends: campaign?.ends_at ? formatDate(campaign?.ends_at, 'YYYY-MM-dd') : null,
    userCampaign: presentUserCampaign(campaign?.user_campaign),
  }
}

export default present
