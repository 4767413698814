import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import Button from '~/components/Button'
import Icon from '~/components/Icon'
import Modal from '~/components/Modal'
import Card from 'components/Card'
import { STRIPE_PREMIUM_CREATOR_PRICE_ID } from '~/global.config'

import * as style from './styles'

// FEATURE_NAME : FREE?
const FEATURES = {
  'Mobile App': true,
  'Incentivized Fan Sharing & Rewards': true,
  'Public Landing Page': true,
  'Crowdmouth Shop (Creator Marketplace)': true,
  'Push Notifications': true,
  'Fan Data Export': false,
  'Create Fan Segments': false,
  '500 Geo-Targeted SMS Text Messages Per Month': false,
}

/**
 * <Describe the CreatorNotPremium component here>
 *
 * @component
 * @usage import CreatorNotPremium from '~/components/CreatorNotPremium'
 * @example
 * <Add an example of how CreatorNotPremium is used here>
 */
const CreatorNotPremium = ({ authStore, stripeStore, notificationsStore }) => {
  const [modalOpen, setModalOpen] = useState(false)

  const { currentUser, authToken } = authStore

  const [coupons, setCoupons] = useState([])

  useEffect(async () => {
    try {
      const response = await fetch(
        'https://staging-api.crowdmouth.com/api/v1/users/partners/coupons?coupon_types[]=partner_register_coupon',
        {
          headers: {
            Authorization: authToken,
          },
        }
      )

      if (response.ok) {
        const data = await response.json()
        setCoupons(data.data.coupons)
      } else {
        console.error('Failed to fetch coupon data.')
      }
    } catch (error) {
      console.error('Error while fetching coupon data:', error)
    }
  }, [])

  const openStripeCheckout = async () => {
    localStorage.setItem('clicked-stripe', 'true')
    const stripeData = await stripeStore.api().createCheckoutSession({
      success_url: window.location.href,
      cancel_url: window.location.href,
      mode: 'subscription',
      price_id: STRIPE_PREMIUM_CREATOR_PRICE_ID,
      quantity: 1,
    })
    if (stripeData && stripeData.success) {
      window.location.href = stripeData.data.url
    } else {
      notificationsStore.addNotification({
        type: 'error',
        message: `Could not open stripe. ${stripeData.message}`,
      })
    }
  }

  return (
    <div css={style.container}>
      <Card className='card'>
        <div className='row title-row'>
          <div className='column column-50'>
            <img className='cm-pro' alt='Crowdmouth' src='/static/cm-pro.svg' />
            <h2 className='title'>GET CLOSER</h2>
            <h2 className='title'>TO YOUR FANS</h2>
            <p className='description'>
              With your Pro subscription, you can easily identify, engage, and
              activate your top fans like never before. Send geo-targeted SMS
              text messages to your fans, organize them into segments based on
              what’s most important to you, and activate them to ignite your
              movement. Take back your fans, and own your data, with Crowdmouth
              Pro.
            </p>
          </div>

          <div className='column column-50'>
            <div className='video' onClick={() => setModalOpen(true)}>
              <Icon icon='frmPlay' className='play-icon' />
            </div>

            <Modal isOpen={modalOpen} onClose={() => setModalOpen(false)}>
              {modalOpen && (
                <iframe
                  style={{ width: '80vh', height: '50vh' }}
                  src='https://www.youtube.com/embed/ZcQkhp6sgxs'
                  title='Crowdmouth Pro'
                  frameBorder='0'
                  allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
                  allowFullScreen
                ></iframe>
              )}
            </Modal>
          </div>
        </div>

        <div className='row info-row'>
          <div className='column'>
            <table className='features-table' cellSpacing='0' cellPadding='0'>
              <thead>
                <tr>
                  <th></th>

                  <th className='first-text'>
                    CROWDMOUTH
                    <br />
                    FREE
                  </th>
                  <th className='pro second-text'>
                    CROWDMOUTH PRO
                    <br />
                    {currentUser?.partnerId === null && <span>$14.99</span>}
                    {currentUser?.partnerId && coupons.length > 0 ? (
                      <span>$9.99</span>
                    ) : null}
                    {currentUser?.partnerId && coupons.length === 0 ? (
                      <span>$14.99</span>
                    ) : null}
                  </th>
                </tr>
              </thead>
              <tbody>
                {Object.keys(FEATURES).map((featureName) => (
                  <tr key={`features_${featureName}`}>
                    <td>{featureName}</td>
                    <td className='center'>
                      {FEATURES[featureName] && <Icon icon='frmTick' />}
                    </td>
                    <td className='center'>
                      <Icon icon='frmTick' />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>

          <div className='column' style={{ alignItems: 'center' }}>
            <div className='upgrade-container'>
              <h3 className='multi-colored-title'>
                UPGRADE TO <span>CROWDMOUTH PRO</span>
              </h3>

              <Button
                style={{ fontWeight: 'bold' }}
                onClick={() => openStripeCheckout()}
              >
                UPGRADE
              </Button>
            </div>
          </div>
        </div>
      </Card>
    </div>
  )
}

CreatorNotPremium.propTypes = {
  stripeStore: PropTypes.object.isRequired,
  notificationsStore: PropTypes.object.isRequired,
  authStore: PropTypes.object.isRequired,
}

CreatorNotPremium.defaultProps = {}

export default CreatorNotPremium
