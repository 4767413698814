import Store, { setObservables } from './Store'
import { observable, action, computed, toJS } from 'mobx'

const OBSERVABLES = {
  _isLoading: { type: observable, defaultValue: false },
  _pageTitle: { type: observable, defaultValue: '' },
  _isDataWritten: { type: observable, defaultValue: false },
  _routeSelected: { type: observable, defaultValue: '' },
}

class StatesStore extends Store {
  constructor(initialData) {
    super(initialData)
    setObservables(this, initialData, OBSERVABLES)
  }

  setIsLoading(value) {
    this._isLoading = value
  }

  get isLoading() {
    return toJS(this._isLoading)
  }

  setPageTitle(value) {
    this._pageTitle = value
  }

  get pageTitle() {
    return toJS(this._pageTitle)
  }

  setIsDataWritten(value) {
    this._isDataWritten = value
  }

  get isDataWritten() {
    return toJS(this._isDataWritten)
  }

  setRouteSelected(value) {
    this._routeSelected = value
  }

  get routeSelected() {
    return toJS(this._routeSelected)
  }
}

export default StatesStore
