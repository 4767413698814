import Store, { setObservables } from './Store'
import UserMassMessagesService from '~/network/UserMassMessagesService.js'
import { observable, action, computed, toJS } from 'mobx'

const OBSERVABLES = {
  _allUserMassMessages: { type: observable, defaultValue: [] },
  _configuration: { type: observable, defaultValue: [] },
}

class UserMassMessagesStore extends Store {
  constructor(initialData) {
    super(initialData)
    setObservables(this, initialData, OBSERVABLES)
  }

  service() {
    return new UserMassMessagesService(this.rootStore)
  }

  async getAllUserMassMessages(params) {
    return await this.accessStoreCache({
      api: 'getAllUserMassMessages',
      params,
      observable: 'allUserMassMessages',
      setter: 'setAllUserMassMessages',
    })
  }

  setAllUserMassMessages(value) {
    this._allUserMassMessages = value
  }

  get allUserMassMessages() {
    return toJS(this._allUserMassMessages)
  }

  async getConfiguration() {
    return await this.accessStoreCache({
      api: 'getConfiguration',
      params: {},
      observable: 'configuration',
      setter: 'setConfiguration',
    })
  }

  setConfiguration(value) {
    this._configuration = value
  }

  get configuration() {
    return toJS(this._configuration)
  }
}

export default UserMassMessagesStore
