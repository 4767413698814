export const CONFIG = {
  withSentry: false,
}

export const API_BASE = process.env.NEXT_PUBLIC_API_BASE || 'https://api.crowdmouth.com/api/v1/'
export const SHORT_URL = process.env.NEXT_PUBLIC_SHORT_URL || 'https://cmth.us/'
export const WEB_URL = process.env.NEXT_PUBLIC_WEB_URL || 'https://app.crowdmouth.com/'
export const STRIPE_PUBLIC_KEY = process.env.NEXT_PUBLIC_STRIPE_PUBLIC_KEY || 'pk_test_51JhKTmFtJf2E9fbSArMYqtHCz0gDdai20lXVnCiLvQDLPXCjh2S0h9Mh98DSTPYUNsxmeMDZymoEFcdOacSVLWJf00h2PjgAkb'
export const STRIPE_PREMIUM_CREATOR_PRICE_ID = process.env.NEXT_PUBLIC_STRIPE_PREMIUM_CREATOR_PRICE_ID || 'price_1KOfhWFtJf2E9fbS2qzNWPDi'
export const SENTRY_DSN = 'https://<sentry-id>@sentry.io/<sentry-sub-id>'
export const GA_TAG = 'G-106NZDHDPS'
export const INTERCOM_APP_ID = 'xcugfhxj'
export const FIREBASE = {
  apiKey: '<api-key>',
  authDomain: '<project-id>.firebaseapp.com',
  databaseURL: 'https://<project-id>.firebaseio.com',
  projectId: '<project-id>',
  storageBucket: '<project-id>.appspot.com',
  messagingSenderId: '<messaging-sender-id>',
  appId: '1:<messaging-sender-id>:web:<token>',
  measurementId: 'G-<measurement-id>',
}
