export const BREAKPOINT_SIZES = {
  screenExtraSmall: {
    size: 0,
    code: 'xs',
  },
  screenSmall: {
    size: 580,
    code: 'sm',
  },
  screenMedium: {
    size: 992,
    code: 'md',
  },
  screenLarge: {
    size: 1200,
    code: 'lg',
  },
  screenExtraLarge: {
    size: 1500,
    code: 'xl',
  },
}

const mediaQuery = (width) =>
  Object.keys(BREAKPOINT_SIZES).reduce((acc, key) => {
    const currentBreakpoint = BREAKPOINT_SIZES[key]
    acc[currentBreakpoint.code] = `@media screen and (${width}: ${currentBreakpoint.size}px)`
    return acc
  }, {})


export const mqMin = mediaQuery('min-width')
export const mqMax = mediaQuery('max-width')
