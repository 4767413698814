import React, { useEffect } from 'react'
import PropTypes from 'prop-types'

import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

/**
 * <Describe the Toast component here>
 *
 * @component
 * @usage import Toast from 'components/Toast'
 * @example
 * <Add an example of how Toast is used here>
 */
const Toast = ({ toasts, onOpen, onClose }) => {
  useEffect(() => {
    toasts.map(toastObj => {
      toast[toastObj.type](toastObj.message, {
        containerId: toastObj.id,
        position: 'bottom-right',
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        onOpen: () => onOpen(toastObj.id),
        onClose: () => onClose(toastObj.id),
      })
    })
  }, [toasts])

  return (
    <ToastContainer
      position='bottom-right'
      autoClose={5000}
      hideProgressBar
      newestOnTop={false}
      closeOnClick
      rtl={false}
      pauseOnFocusLoss
      draggable
      pauseOnHover
    />
  )
}

Toast.propTypes = {
  toasts: PropTypes.array,
  onOpen: PropTypes.func,
  onClose: PropTypes.func,
}

Toast.defaultProps = {
  typetoasts: [],
  onOpen: () => {},
  onClose: () => {},
}

export default Toast
