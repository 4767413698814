import presentUser from 'presenters/userPresenter'
import presentAttachment from 'presenters/attachmentPresenter'
import { isEmpty } from 'lodash'

const present = (feed = {}) => {
  if (isEmpty(feed)) { return {} }

  return {
    id: feed?.id,
    postId: feed?.actionable_id,
    type: feed?.actionable_type,
    timeCreated: feed?.created_at_formatted,
    relationshipType: feed?.actionable?.relationship_type,
    content: feed?.actionable?.content,
    totalLikes: feed?.actionable?.total_likes,
    images: (feed?.actionable?.attachments || []).map(presentAttachment),
    user : presentUser(feed?.user),
  }
}

export default present
