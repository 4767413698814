import Service from './Service'
import presentApiPagination from '~/presenters/apiPaginationPresenter'
// import presentReward from '~/presenters/rewardPresenter'

export const SERVICES = {
  getAllUserMassMessages: {
    type: 'get',
    present: resp => {
      return {
        success: resp?.success,
        data: resp?.data?.items || [],
        ...presentApiPagination(resp),
      }
    },
  },
  createMassMessage: {
    type: 'post',
    urlParam: '/',
  },
  messageCost: {
    type: 'get',
    urlParam: '/:id/cost',
  },
  costByRules: {
    type: 'post',
    urlParam: '/cost_by_rules',
  },
  deleteMassMessage: {
    type: 'delete',
    urlParam: '/:id',
  },
  getConfiguration: {
    type: 'get',
    urlParam: '/configuration',
    present: resp => {
      return resp?.data || {}
    },
  },
  getUsers: {
    type: 'postWithFormData',
    urlParam: '/users',
  },
  exportUsers: {
    type: 'post',
    urlParam: '/users.xlsx',
    responseType: 'blob',
    extraHeaders: {
      Accept:
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    },
  },
}

/**
 * Instance of UserMassMessages Service.
 *
 * @class
 * @usage `import UserMassMessagesService from '~/network/UserMassMessagesService'`
 */
export default class UserMassMessagesService extends Service {
  constructor(props) {
    super(props)
    this.services = SERVICES
  }

  serviceBase() {
    return 'user_mass_messages'
  }
}
