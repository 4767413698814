import React from 'react'

import * as style from './styles'

/**
 * <Describe the CardSubtitle component here>
 *
 * @component
 * @usage import CardSubtitle from 'components/CardSubtitle'
 * @example
 * <Add an example of how CardSubtitle is used here>
 */
const CardSubtitle = (props) => <h5 css={style.subtitle} {...props} />

export default CardSubtitle
