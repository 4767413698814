import { css } from '@emotion/react'

export const imageAvatar = ({ borderRadius = 0 }) => css`
  width: 3rem;
  height: 3rem;
  border-radius: ${borderRadius};
  cursor: pointer;
`

export const editContainer = css`
  position: relative;
`

export const buttonEdit = css`
  position: absolute;
  bottom: -0.5rem;
  left: 0;
  right: 0;
  margin: auto;
`
