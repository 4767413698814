import Service from './Service'
import presentAnalytics from 'presenters/analyticsPresenter'

export const SERVICES = {
  getDashboardAnalytics: {
    type: 'get',
    urlParam: '/dashboard',
    present: resp => {
      return {
        success: resp?.success,
        data: presentAnalytics(resp?.data || {}),
      }
    },
  },
}

/**
 * Instance of Analytics Service.
 *
 * @class
 * @usage `import AnalyticsService from 'network/AnalyticsService'`
 */
export default class AnalyticsService extends Service {
  constructor(props) {
    super(props)
    this.services = SERVICES
  }

  serviceBase() {
    return 'analytics'
  }
}
