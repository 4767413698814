import Store, { setObservables } from './Store'
import DataService from '~/network/DataService.js'
import { observable, action, computed, toJS } from 'mobx'

const OBSERVABLES = {
  // _allCountries: { type: observable, defaultValue: [] },
}

class DataStore extends Store {
  constructor(initialData) {
    super(initialData)
    setObservables(this, initialData, OBSERVABLES)
  }

  service() {
    return new DataService(this.rootStore)
  }

  async getCountries() {
    return await this.api().getCountries()
  }

  async getStates(countryName) {
    return await this.api().getStates({ country_name: countryName })
  }

  async getCities(countryName, stateName) {
    return await this.api().getCities({
      country_name: countryName,
      state_name: stateName,
    })
  }

  async getCity(id) {
    return await this.api().getCity({ id })
  }
}

export default DataStore
