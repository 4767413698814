import { css } from '@emotion/react'
import colors from 'styles/colors'
import { mqMax } from 'styles/mixins/mediaQueries'

export const container = css`
  height: 10px;
  width: 85%;
  background-color: ${colors.gray};
  margin: 20px;
  cursor: pointer;
  transition: transform 0.3s ease-in-out;

  &:hover {
    transform: scale(1.05);
  }

  ${mqMax.sm} {
    display: none;
  }

  ${mqMax.md} {
    display: none;
  }
`

export const filler = css`
  height: 100%;
  background-color: ${colors.orange};
  border-radius: inherit;
  text-align: right;
`
export const completion = css`
  font-size: 0.8rem;
  margin: 0;
  padding-top: 0.2rem;
  color: ${colors.white};
`
