import { isEmpty } from 'lodash'

const present = (attachment = {}) => {
  if (isEmpty(attachment)) { return {} }

  return {
    id: attachment?.id,
    thumbnail: attachment?.thumbnail,
    large: attachment?.large,
    original: attachment?.original,
  }
}

export default present
