import { css } from '@emotion/react'
import colors from 'styles/colors'
import { HEADER_HEIGHT, zIndexes } from 'styles/constants'
import { mqMax } from 'styles/mixins/mediaQueries'

const PADDING_HORIZONTAL = '1rem'
const PADDING_HORIZONTAL_SM = '0.5rem'

export const header = css`
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: linear-gradient(90deg, ${colors.pink} 25%, ${colors.orange} 100%);
  height: ${HEADER_HEIGHT};
  width: calc(100% - ${PADDING_HORIZONTAL} * 2);
  padding: 0 ${PADDING_HORIZONTAL};
  color: ${colors.white};
  z-index: ${zIndexes.top};

  ${mqMax.sm} {
    width: calc(100% - ${PADDING_HORIZONTAL_SM} * 2);
    padding: 0 ${PADDING_HORIZONTAL_SM};
  }
`

export const logoAndSearchContainer = css`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: inherit;
  width: 100%;

  ${mqMax.md} {
    width: 100%;
  }
`

export const logo = css`
  width: 10rem;
`
export const loader = () => css`
  flex: 1;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  min-height: 10rem;

  svg {
    font-size: 5rem;
    color: #e96b83;
  }
`

export const button = css`
  border: 1px solid #fff;
  color: #fff;

  &:hover {
      box-shadow: 0rem 0rem 0.3rem 0.1rem #fff;
    }
`
