import Service from './Service'
import presentApiPagination from '~/presenters/apiPaginationPresenter'
// import presentReward from '~/presenters/rewardPresenter'

export const SERVICES = {
  getAllSegments: {
    type: 'get',
  },
  createSegment: {
    type: 'post',
    urlParam: '/',
  },
  updateSegment: {
    type: 'post',
    urlParam: '/:id',
  },
  deleteSegment: {
    type: 'delete',
    urlParam: '/:id',
  },
}

/**
 * Instance of UserSegments Service.
 *
 * @class
 * @usage `import UserSegmentsService from '~/network/UserSegmentsService'`
 */
export default class UserSegmentsService extends Service {
  constructor(props) {
    super(props)
    this.services = SERVICES
  }

  serviceBase() {
    return 'users/segments'
  }
}
