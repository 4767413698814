import { css } from '@emotion/react'
import colors from 'styles/colors'
import { HEADER_HEIGHT, zIndexes } from 'styles/constants'
import { mqMax } from 'styles/mixins/mediaQueries'

const PADDING_HORIZONTAL = '1rem'
const PADDING_HORIZONTAL_SM = '0.5rem'

export const header = css`
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: linear-gradient(90deg, ${colors.pink} 25%, ${colors.orange} 100%);
  height: ${HEADER_HEIGHT};
  width: calc(100% - ${PADDING_HORIZONTAL} * 2);
  padding: 0 ${PADDING_HORIZONTAL};
  color: ${colors.white};
  z-index: ${zIndexes.top};

  ${mqMax.sm} {
    width: calc(100% - ${PADDING_HORIZONTAL_SM} * 2);
    padding: 0 ${PADDING_HORIZONTAL_SM};
  }
`

export const logoAndSearchContainer = css`
  display: flex;
  align-items: center;
  height: inherit;

  ${mqMax.md} {
    width: 100%;
  }
`

export const logo = css`
  width: 10rem;
  cursor: pointer;
`

export const inputSearch = css`
  margin-bottom: 0;
  margin-left: 5rem;
  width: 18rem;

  ${mqMax.md} {
    margin-left: auto;
  }

  ${mqMax.sm} {
    display: none;
  }
`

export const actionsContainer = css`
  height: inherit;
  display: flex;
  align-items: center;
`

export const userInfo = css`
  cursor: pointer;
  display: flex;
  align-items: center;
`

export const username = css`
  border-left: 0.1rem solid ${colors.white};
  margin-left: 1rem;
  padding-left: 1rem;
  text-decoration: none;

  ${mqMax.md} {
    display: none;
  }
`

export const premium = css`
  margin-left: 1rem;
  padding-left: 1rem;
  text-decoration: none;
  border-left: 1px solid ${colors.white};
`

export const avatar = css`
  height: 30px;
  width: 30px;
  padding: 0;
  background-color: ${colors.white};
  border: 0.2rem solid ${colors.white};
  margin-left: 0.5rem;
  cursor: pointer;

  ${mqMax.md} {
    margin-left: 1rem;
  }
`

export const settings = css`
  width: 2.2rem;
  color: ${colors.white};
  border-rigth: 1px solid ${colors.white};
  cursor: pointer;
  height: 2.5rem;
`

export const settingsContainer = css`
  position: relative;
  width: 3rem;
  height: 1.6rem;
  display: flex;
  justify-content: center;
  align-items: center;
`
