import { isEmpty } from 'lodash'

const present = (address = {}) => {
  if (isEmpty(address)) { return {} }

  return {
    country: address?.country,
    city: address?.city,
    address1: address?.address_line_1,
    address2: address?.address_line_2,
    zipcode: address?.zipcode,
    phone: address?.phone_number,
  }
}

export default present
