import Store, { setObservables } from './Store'
import RewardTemplatesService from 'network/RewardTemplatesService.js'
import { observable, action, computed, toJS } from 'mobx'

const OBSERVABLES = {
  _allTemplates: { type: observable, defaultValue: [] },
  _customTemplate: {
    type: observable,
    defaultValue: {
      id: -1,
      label: 'Custom Reward',
      order: 999,
      type: 'custom',
    },
  },
}

class RewardTemplatesStore extends Store {
  constructor(initialData) {
    super(initialData)
    setObservables(this, initialData, OBSERVABLES)
  }

  service() {
    return new RewardTemplatesService(this.rootStore)
  }

  async getAllTemplates() {
    const allTemplatesCall = await this.api().getAllTemplates()
    const custom = {
      id: -1,
      label: 'Custom',
      type: 'custom',
      order: 999,
    }

    const templates = [
      ...allTemplatesCall?.data,
      custom,
    ]

    return templates
  }

  setAllTemplates(value) {
    this._allTemplates = value
  }

  get allTemplates() {
    return toJS(this._allTemplates)
  }

  get customTemplate() {
    return toJS(this._customTemplate)
  }
}

export default RewardTemplatesStore
