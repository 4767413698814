import Store, { setObservables } from './Store'
import InAppPurchaseItemService from '~/network/InAppPurchaseItemService.js'
import { observable, action, computed, toJS } from 'mobx'

const OBSERVABLES = {
  _allInAppPurchaseItems: { type: observable, defaultValue: [] },
}

class InAppPurchaseItemStore extends Store {
  constructor(initialData) {
    super(initialData)
    setObservables(this, initialData, OBSERVABLES)
  }

  service() {
    return new InAppPurchaseItemService(this.rootStore)
  }

  async getAllInAppPurchaseItems(params) {
    return await this.accessStoreCache({
      api: 'getAllInAppPurchaseItems',
      params,
      observable: 'allInAppPurchaseItems',
      setter: 'setAllInAppPurchaseItems',
    })
  }

  setAllInAppPurchaseItems(value) {
    this._allInAppPurchaseItems = value
  }

  get allInAppPurchaseItems() {
    return toJS(this._allInAppPurchaseItems)
  }
}

export default InAppPurchaseItemStore
