import Service from './Service'
import presentApiPagination from 'presenters/apiPaginationPresenter'
import presentCampaign from 'presenters/campaignPresenter'
import presentUserCampaign from 'presenters/userCampaignPresenter'
import presentAnalytics from 'presenters/analyticsPresenter'

export const SERVICES = {
  getCampaign: {
    type: 'get',
    urlParam: '/by_code/:code',
    present: resp => {
      return {
        success: resp?.success,
        data: presentCampaign(resp?.data),
      }
    },
  },
  updateCampaign: {
    type: 'putWithFormData',
    urlParam: '/:id',
  },
  deleteCampaign: {
    type: 'delete',
    urlParam: '/:id',
  },
  getAllCampaigns: {
    type: 'get',
    present: resp => {
      return {
        success: resp?.success,
        data: (resp?.data?.items || []).map(presentCampaign),
        ...presentApiPagination(resp),
      }
    },
  },
  getCampaignStats: {
    type: 'get',
    urlParam: '/:id/stats',
    present: resp => {
      return {
        success: resp?.success,
        data: presentAnalytics(resp?.data || {}),
      }
    },
  },
  shareCampaign: {
    type: 'get',
    urlParam: '/:id/share',
    present: resp => {
      return {
        success: resp?.success,
        data: presentUserCampaign(resp?.data),
      }
    },
  },
  shareCampaignViaEmail: {
    type: 'get',
    urlParam: '/:id/unauthorized_share',
    present: resp => {
      return {
        success: resp?.success,
        data: presentUserCampaign(resp?.data),
      }
    },
  },
  createUserCampaign: {
    type: 'postWithFormData',
    present: resp => {
      return {
        success: resp?.success,
        data: presentCampaign(resp.data),
      }
    },
  },
}

/**
 * Instance of Campaigns Service.
 *
 * @class
 * @usage `import CampaignsService from 'network/CampaignsService'`
 */
export default class CampaignsService extends Service {
  constructor(props) {
    super(props)
    this.services = SERVICES
  }

  serviceBase() {
    return 'campaigns'
  }
}
