import Service from './Service'

export const SERVICES = {
  getAllInAppPurchaseItems: {
    type: 'get',
    urlParam: '/',
    // present: (resp) => {
    //   return resp.data?.items || []
    // }
  },
}

/**
 * Instance of Data Service.
 *
 * @class
 * @usage `import InAppPurchaseItemService from '~/network/InAppPurchaseItemService'`
 */
export default class InAppPurchaseItemService extends Service {
  constructor(props) {
    super(props)
    this.services = SERVICES
  }

  serviceBase() {
    return 'in_app_purchase_items'
  }
}
