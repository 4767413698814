import { css } from '@emotion/react'
import { HEADER_HEIGHT, SIDEBAR_WIDTH } from 'styles/constants'
import colors from 'styles/colors'
import { mqMax } from 'styles/mixins/mediaQueries'

const CHILD_PADDING = '2rem'
const CHILD_PADDING_MD = '1rem'
const CHILD_PADDING_SM = '0.5rem'

export const layout = css`
  display: flex;
  flex-direction: column;
  min-height: 100%;
  height: auto;
`

export const main = css`
  display: flex;
  min-height: calc(100vh - ${HEADER_HEIGHT});
  height: auto;
  margin-top: ${HEADER_HEIGHT};
`

export const childrenContainer = css`
  width: calc(100% - ${SIDEBAR_WIDTH} - ${CHILD_PADDING} * 2);
  padding: ${CHILD_PADDING};
  background: ${colors.background};
  flex: 1;

  > section {
    width: 100%;
  }

  ${mqMax.md} {
    width: calc(100% - ${SIDEBAR_WIDTH} - ${CHILD_PADDING_MD} * 2);
    padding: ${CHILD_PADDING_MD};
  }

  ${mqMax.sm} {
    width: calc(100% - ${SIDEBAR_WIDTH} - ${CHILD_PADDING_SM} * 2);
    padding: ${CHILD_PADDING_SM};
  }
`
