import presentUser from 'presenters/userPresenter'
import presentAddress from 'presenters/addressPresenter'
import presentReward from 'presenters/rewardPresenter'
import { isEmpty } from 'lodash'

const present = (request = {}) => {
  if (isEmpty(request)) { return {} }

  return {
    id: request?.id,
    status: request?.status,
    rewardType: request?.class_name,
    video: request?.attachments?.[0]?.original,
    note: request?.note,
    paidInUsd: request?.paid_in_usd,
    usdPaymentStatus: request?.usd_payment_status,
    usdPaymentSource: request?.usd_payment_source,
    balance: parseInt(request?.user_balance?.balance),
    address: presentAddress(request?.address),
    reward: presentReward(request?.reward),
    user: presentUser(request?.user),
  }
}

export default present
