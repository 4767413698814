import React, { useEffect, useState } from 'react'
import { inject } from 'mobx-react'

import Icon from 'components/Icon'
import Spinner from '~/components/Spinner'
import NotPremiumUser from 'components/Creator/NotPremium/index'
import Chip from 'components/Chip'
import * as style from './style'

import { router } from 'lib/router'

const NewTuneCoreUser = ({ rootStore }) => {
  const { authStore, stripeStore, notificationsStore } = rootStore
  const isPremiumUser = authStore.premiumCreator

  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    setIsLoading(true)
    if (isPremiumUser === true) {
      router.replace(router.routes.home)
    } else if (isPremiumUser === false) {
      setIsLoading(false)
    }
  }, [isPremiumUser])

  useEffect(async () => {
    const clickedUpgrade = localStorage.getItem('clicked-stripe')

    if (clickedUpgrade !== null && clickedUpgrade !== undefined) {
      setIsLoading(true)
      const timer = setTimeout(() => {
        localStorage.removeItem('clicked-stripe')
        setIsLoading(false)
      }, 10000)
      await authStore.reloadUser()
      return () => {
        clearTimeout(timer)
      }
    }
  }, [])

  const handleRefreshClick = () => {
    window.location.reload();
  };


  return (
    <>
      <header css={style.header}>
        <div css={style.logoAndSearchContainer}>
          <div css={style.logo}>
            <Icon icon='crowdmouth' />
          </div>
          <Chip
            css={style.button}
            isClickable
            onClick={() => {
              router.push('/login')
              authStore.logout()
            }}
          >
            Logout
          </Chip>
        </div>
      </header>
      <div
        style={{
          backgroundColor: '#F9F9FC',
          height: '100vh',
          marginTop: '-50px',
        }}
      >
        <div style={{ marginTop: '50px', padding: '4em 0' }}>
          {isLoading ? (
            <>
            <div style={{ textAlign: 'center', paddingTop: '100px' }}>
        <h1>Redirecting...</h1>
        <p>If you are not redirected in 10 seconds, please click {' '}
                <span
                  style={{ textDecoration: 'underline', cursor: 'pointer', color: '#e96b83' }}
                  onClick={handleRefreshClick}
                >
                  here
                </span>.</p>
      </div>
            <div css={style.loader}>
              <Spinner />
            </div>
          </>
          ) : (
            <NotPremiumUser
              authStore={authStore}
              stripeStore={stripeStore}
              notificationsStore={notificationsStore}
            />
          )}
        </div>
      </div>
    </>
  )
}

export default inject('rootStore')(NewTuneCoreUser)
