import React, { useState } from 'react'

import { STRIPE_PREMIUM_CREATOR_PRICE_ID } from '~/global.config'
import { router } from '~/lib/router'
import Chip from 'components/Chip'
import Button from '~/components/Button'

import * as style from './style'

const SettingsModal = ({ authStore, setOpen, rootStore }) => {
  const { stripeStore, notificationsStore } = rootStore
  const { currentUser } = authStore
  const [isSelected, setIsSelected] = useState(true)

  const toggleHandler = () => {
    setIsSelected(!isSelected)
  }

  const openInNewTab = (url) => {
    const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
    if (newWindow) {
      newWindow.opener = null
    }
  }

  const openStripeCheckout = async () => {
    // router.push('/admin/payment_details')
    const stripeData = await stripeStore.api().createCheckoutSession({
      success_url: window.location.href,
      cancel_url: window.location.href,
      mode: 'subscription',
      price_id: STRIPE_PREMIUM_CREATOR_PRICE_ID,
      quantity: 1,
    })
    if (stripeData && stripeData.success) {
      window.location.href = stripeData.data.url
    } else {
      notificationsStore.addNotification({
        type: 'error',
        message: `Could not open stripe. ${stripeData.message}`,
      })
    }
  }

  return (
    <>
      <div
        role='presentation'
        css={style.overlay}
        onClick={() => setOpen(false)}
      />
      <div css={style.mainContainer}>
        <div className='modal' css={style.modal}>
          <div css={style.modalHeader}>
            <h1>Settings</h1>
            <button className='close' onClick={() => setOpen(false)}>
              &times;
            </button>
          </div>
          <div css={style.modalContent}>
            <div className='badges'>
              {!authStore.premiumCreator && (
                <Chip
                  isActive={isSelected}
                  isInactive={!isSelected}
                  onClick={() => () => toggleHandler}>
                  Subscription
                </Chip>
              )}
              <Chip
                css={style.chipCategory}
                isClickable
                onClick={() =>
                  openInNewTab(
                    'https://www.iubenda.com/privacy-policy/37558572'
                  )
                }>
                Privacy Policy
              </Chip>
              <Chip
                css={style.chipCategory}
                isClickable
                onClick={() => router.push('/update-info')}>
                Edit Profile
              </Chip>
              <Chip
                css={style.chipCategory}
                isClickable
                onClick={() => {
                  setOpen(false)
                  router.push('/login')
                  authStore.logout()
                }}>
                Logout
              </Chip>
            </div>
            {!authStore.premiumCreator && <hr className='mid-line' />}
            {!authStore.premiumCreator ? (
              <div css={style.details}>
                <h2 className='details-header'>Plan</h2>
                {!currentUser?.partnerId && <><h3 className='details-subheader'>Crowdmouth Pro -$14.99</h3><p className='details-p'>Billed monthly, with 7-day trial</p></>}
                {currentUser?.partnerId && <><h3 className='details-subheader'>Crowdmouth Pro -$9.99</h3><p className='details-p'>Billed monthly</p></>}
                <button id='link' onClick={() => openStripeCheckout()}>
                  Change Plan
                </button>
              </div>
            ) : null}
          </div>
          <div css={style.btn}>
            <Button onClick={() => setOpen(false)} style={{ width: '10rem' }}>
              Close
            </Button>
          </div>
        </div>
      </div>
    </>
  )
}

export default SettingsModal
