import Store, { setObservables } from './Store'
import UserActionsService from 'network/UserActionsService.js'
import { observable, action, computed, toJS } from 'mobx'

import { imei } from 'utils/fingerprint'
import { IsDevelopment } from '@sowlutions-tech/next/common/utils'

class UserActionsStore extends Store {
  constructor(initialData) {
    super(initialData)
  }

  service() {
    return new UserActionsService(this.rootStore)
  }

  async create(params) {
    if (IsDevelopment) {
      return {}
    }

    return await this.api().create({
      action_type: 'click',
      device_imei: imei(),
      device_type: 'browser',
      ...params,
    })
  }
}

export default UserActionsStore
